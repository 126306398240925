import { App } from 'vue';
import {
  createI18n,
  LocaleMessages,
  VueMessageType,
  useI18n as originalUseI18n,
  UseI18nOptions
} from 'vue-i18n';

function loadLocaleMessages() {
  const locales = require.context('../locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages: Record<string, LocaleMessages<VueMessageType>> = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key).default;
    }
  });
  return messages;
}

export function loadI18n(app: App) {
  const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages: loadLocaleMessages()
  });
  app.use(i18n);
}

export function useI18n(options?: UseI18nOptions) {
  return originalUseI18n<unknown, string>(options);
}
