export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "accessDeniedContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may not have a permission to access the requested resource"])},
        "accessDeniedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access denied"])},
        "serverErrorContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong so we couldn't fulfill your request. Please refresh the page or try again later."])},
        "serverErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unexpected server error"])}
      },
      "fr": {
        "accessDeniedContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est possible que vous ne soyez pas autorisé(e) à accéder à la ressource demandée"])},
        "accessDeniedTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès refusé"])},
        "serverErrorContent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite et nous n'avons pas pu accéder à votre demande. Veuillez rafraîchir la page ou réessayer plus tard."])},
        "serverErrorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de serveur inattendue"])}
      }
    }
  })
}
