
import { computed, defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { programStore } from '@/store/program';
import { useI18n } from '@/services/i18n';
import Footer from '@/components/common/Footer.vue';
import { logEvent } from '@/services/amplitude';
import { PendoUserType } from '@/services/pendo';

export default defineComponent({
  name: 'ProgramLayout',
  components: {
    Footer
  },
  setup() {
    const route = useRoute();

    const auth = useStore(authStore);
    const program = useStore(programStore);
    const routeName = computed(() => {
      if (route.name !== undefined && route.name !== null) {
        return (route.name as string).split('.')[1];
      }
      return '';
    });
    const userName = computed(() => auth.userName);
    const isSchoolAdmin = computed(() => auth.isSchoolAdmin);

    const insightsUrl = process.env.VUE_APP_ALTUS_INSIGHTS_URL;

    onMounted(async () => {
      await Promise.all([
        auth.launchSupportWidget('program'),
        auth.launchPendoTracker(PendoUserType.PROGRAM),
        program.loadSchoolsAndTests.run()
      ]);
    });

    return {
      userName,
      isSchoolAdmin,
      t: useI18n().t,
      auth,
      logEvent,
      routeName,
      insightsUrl
    };
  }
});
