<template>
  <div
    class="relative"
    :style="{
      width: size,
      height: size
    }"
  >
    <span class="sr-only">
      {{ t('srPageLoading') }}
    </span>
    <div
      class="absolute inset-0 border-solid rounded-full border-4 border-opacity-10"
      :class="getBorderColor"
    ></div>
    <div
      class="absolute inset-0 border-solid rounded-full border-4 animate-spin inner"
      :class="getBorderColor"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useI18n } from '@/services/i18n';

const variants: Record<string, string | undefined> = {
  primary: 'border-blue',
  secondary: 'border-teal',
  danger: 'border-red',
  success: 'border-green',
  warning: 'border-orange',
  info: 'border-babyblue',
  neutral: 'border-gray'
};

export default defineComponent({
  name: 'Spinner',
  props: {
    size: { type: String, default: '2rem' },
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string) => Object.keys(variants).includes(value)
    }
  },
  setup(props) {
    const getBorderColor = computed(() => {
      return variants[props.variant] ?? '';
    });

    return {
      getBorderColor,
      t: useI18n().t
    };
  }
});
</script>

<style lang="postcss" scoped>
.inner {
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
</style>

<i18n>
{
  "en": {
    "srPageLoading": "Page loading"
  },
  "fr": {
    "srPageLoading": "Chargement de page"
  }
}
</i18n>
