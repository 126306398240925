
import { defineComponent, ref, watchEffect } from 'vue';
import Checkmark from '@/components/common/Checkmark.vue';
import { randomId } from '@/services/util';

export default defineComponent({
  name: 'Checkbox',
  inheritAttrs: false,
  components: { Checkmark },
  props: {
    id: { type: String, default: () => `checkbox-id-${randomId()}` },
    modelValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const checked = ref(false);

    watchEffect(() => (checked.value = props.modelValue));

    function onChange(event: Event) {
      const checked = (event.target as HTMLInputElement).checked;
      emit('update:modelValue', checked);
    }

    return { onChange, checked };
  }
});
