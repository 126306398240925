import {
  AugmentedSubmission,
  Permission,
  RaterStats,
  Rating,
  School,
  Submission,
  Test,
  User
} from '@/interfaces/interfaces';
import { injectApiClient } from '@/services/apiClient';
import { computed, ComputedRef, DeepReadonly, ref } from 'vue';
import { defineStore, mutableRef, useStore } from './lib/store';
import { asyncTask } from './lib/asyncTask';
import { authStore } from './auth';
import { formatDate, formatLongDate } from '@/services/util';
import { useI18n } from '@/services/i18n';
import { updatePendoUser } from '@/services/pendo';

export const programStore = defineStore('program', () => {
  const apiClient = injectApiClient();
  const auth = useStore(authStore);
  const i18n = useI18n({ useScope: 'global' });

  const schools = ref<School[] | null>(null);
  const tests = ref<Test[] | null>(null);
  const currentUserPermissions = ref<Permission[] | null>(null);
  const userHasAccess = ref<boolean>(false);
  const submissions = ref<Submission[] | null>(null);
  const raters = ref<DeepReadonly<User[]> | null>(null);
  const stats = ref<RaterStats[] | null>(null);
  const permissions = ref<Permission[] | null>(null);

  const currentSchoolId = ref<string | null>(null);
  const currentTestId = ref<string | null>(null);

  const submissionsFilter = mutableRef<string | null>(null);

  const currentSchool = computed(() =>
    schools.value?.find(school => school.id === currentSchoolId.value)
  );
  const currentTest = computed(() =>
    tests.value?.find(test => test.id === currentTestId.value)
  );
  const currentPermissions = computed(() => {
    if (currentSchoolId.value === null) {
      return [];
    }

    const schoolPermissions = auth.user?.schoolPermissions.find(
      sp => sp.schoolId === currentSchoolId.value
    );

    const userPermissions =
      currentUserPermissions.value
        ?.filter(p => schoolPermissions?.insightsPermissionIds.includes(p.id))
        .map(p => p.key) ?? [];

    return userPermissions;
  });

  const ratersWithPermissions = computed(() => {
    return raters.value === null
      ? []
      : raters.value.flatMap(r => {
          const schoolPermissions = r.schoolPermissions.find(
            sp =>
              currentSchool.value !== undefined && sp.schoolId === currentSchool.value.id
          );

          if (schoolPermissions === undefined) {
            return [];
          }

          const userPermissions =
            permissions.value === null
              ? []
              : permissions.value
                  .filter(p => schoolPermissions.insightsPermissionIds.includes(p.id))
                  .map(p => p.key) ?? [];

          if (userPermissions.length === 0) {
            return [];
          }

          const conditions = ['manageSnapshotReviewers', 'reviewSnapshot'];

          if (conditions.some(el => userPermissions.includes(el))) {
            return [r];
          }

          // return needed to avoid undefined
          return [];
        });
  });

  const currentSchoolTests = computed(() => {
    if (tests.value === null || currentSchoolId.value === null) {
      return null;
    }
    return tests.value
      .filter(
        test =>
          test.schoolTests &&
          test.schoolTests.some(st => st.schoolId === currentSchoolId.value)
      )
      .sort((a, b) => a.name.localeCompare(b.name));
  });

  const resultsAvailableAt = computed(() => {
    const availabilities =
      currentSchool.value?.tests?.flatMap(t => t.resultsAvailableAt ?? []) ?? [];

    const availability = availabilities.find(
      a => a.applicationCycleYear === currentTest.value?.cycle?.name
    );

    return availability?.availableAt === undefined
      ? undefined
      : new Date(availability?.availableAt);
  });

  const areResultsAvailable = computed(() =>
    resultsAvailableAt.value === undefined ? true : new Date() >= resultsAvailableAt.value
  );

  const programHasAccess = computed(() => {
    const hasAccess =
      currentTest.value?.schoolTests?.some(
        st => st.schoolId === currentSchoolId.value && st.programHasAccess === true
      ) ?? false;

    return hasAccess;
  });

  const formattedResultsAvailableAt = computed(() =>
    resultsAvailableAt.value === undefined
      ? undefined
      : formatLongDate(resultsAvailableAt.value, i18n.locale.value)
  );

  const useMultiReviewers = computed(() => {
    return currentTest.value !== undefined && currentSchoolId.value !== null
      ? currentTest.value.schoolTests?.find(st => st.schoolId === currentSchoolId.value)
          ?.useMultiReviewers ?? false
      : false;
  });

  function getAverageScore(ratings: Rating[]) {
    let sum = 0;
    let count = 0;
    let averageScore: number | null = null;

    for (const r of ratings) {
      if (r.score !== null) {
        sum += r.score;
        count++;
      }
    }

    if (count > 0 && sum > 0) {
      averageScore = parseFloat((sum / count).toFixed(2));
    }

    return averageScore;
  }

  const augmentedSubmissions: ComputedRef<AugmentedSubmission[]> = computed(
    () =>
      submissions.value?.map(s => ({
        submission: s,
        averageScore: s.ratings !== undefined ? getAverageScore(s.ratings) : null,
        raters:
          raters.value === null || s.ratings === undefined
            ? []
            : s.ratings.map(rating => {
                const rater = raters.value?.find(r => rating.userId === r.id);
                if (rater === undefined) {
                  throw new Error(
                    `Unable to find rater with the provided userId. Rating-ID: ${rating.id}`
                  );
                }
                return rater;
              }),
        formattedCompletedAt:
          s.completedAt !== null ? formatDate(s.completedAt, i18n.locale.value) : null
      })) ?? []
  );

  const filteredSubmissions = computed(() => {
    if (submissionsFilter.value === null) {
      return augmentedSubmissions.value;
    }
    return augmentedSubmissions.value.filter(
      s =>
        submissionsFilter.value === null ||
        s.submission.user?.profile?.name
          .toLowerCase()
          .includes(submissionsFilter.value.toLowerCase()) ||
        s.submission.user?.emails[0].address
          .toLowerCase()
          .includes(submissionsFilter.value.toLowerCase()) ||
        s.submission.userId.toLowerCase().includes(submissionsFilter.value.toLowerCase())
    );
  });

  const loadRaters = asyncTask(async () => {
    if (currentSchoolId.value !== null && currentTestId.value !== null) {
      const data = await apiClient.getRaters(currentSchoolId.value, currentTestId.value);
      raters.value = data.raters.sort((a, b) => {
        const aName = a.profile?.name;
        const bName = b.profile?.name;
        return aName !== undefined && bName !== undefined
          ? aName.localeCompare(bName)
          : aName === undefined && bName === undefined
          ? 0
          : aName !== undefined && bName === undefined
          ? -1
          : 1;
      });
      stats.value = data.stats;
      permissions.value = data.permissions;
    }
  });

  const loadSubmissions = asyncTask(async () => {
    if (currentSchoolId.value !== null && currentTestId.value !== null) {
      const data = await apiClient.getSubmissions(
        currentSchoolId.value,
        currentTestId.value
      );
      submissions.value = data.sort((a, b) => {
        const aName = a.user?.profile?.name;
        const bName = b.user?.profile?.name;
        return aName !== undefined && bName !== undefined
          ? aName.localeCompare(bName)
          : aName === undefined && bName === undefined
          ? 0
          : aName !== undefined && bName === undefined
          ? -1
          : 1;
      });
    }
  });

  function selectSchool(schoolId: string | null) {
    currentSchoolId.value = schoolId;
    currentTestId.value = null;
    submissions.value = null;
    raters.value = null;
    stats.value = null;
    if (currentSchoolId.value !== null) {
      updatePendoUser({
        userId: auth.user?.id,
        schoolId: currentSchoolId.value,
        role: auth.user?.roles?.[0],
        isAltan: process.env.VUE_APP_INTERNAL_DOMAIN_ADDRESSES?.split(',').some(
          (domain: string) => {
            return auth.user?.emails.some(
              (email: { address: string; verified: boolean }) =>
                email?.address?.toLowerCase().includes(domain)
            );
          }
        )
      });
    }
  }

  async function selectTest(testId: string | null) {
    currentTestId.value = testId;
    if (
      !programHasAccess.value ||
      !areResultsAvailable.value ||
      currentTestId.value === null
    ) {
      submissions.value = null;
      raters.value = null;
      stats.value = null;
      return;
    }
    if (
      auth.isSchoolAdmin &&
      currentPermissions.value.includes('manageSnapshotReviewers')
    ) {
      userHasAccess.value = true;
      await Promise.all([loadRaters.run(), loadSubmissions.run()]);
    } else if (
      auth.user !== null &&
      currentPermissions.value.includes('reviewSnapshot')
    ) {
      userHasAccess.value = true;
      raters.value = [auth.user];
      stats.value = null;
      await loadSubmissions.run();
    } else {
      userHasAccess.value = false;
    }
  }

  const loadSchoolsAndTests = asyncTask(async () => {
    const data = await apiClient.getSchoolsAndTests();
    schools.value = data.schools.sort((a, b) => a.name.localeCompare(b.name));
    tests.value = data.tests;
    currentUserPermissions.value = data.permissions;
  });

  const assignRaters = asyncTask(async (submissionIds: string[], userIds: string[]) => {
    if (currentSchoolId.value === null || currentTestId.value === null) {
      throw new Error('Cannot assign raters without school and test id');
    }

    const params = {
      schoolId: currentSchoolId.value,
      testId: currentTestId.value,
      submissionIds,
      userIds
    };
    const ratings = await apiClient.createRating(params);

    for (const submissionId of params.submissionIds) {
      const submission = submissions.value?.find(s => s.id === submissionId);

      if (submission === undefined) {
        throw new Error('Could not find the submission with the provided ID');
      }

      if (submission.ratings === undefined) {
        submission.ratings = [];
      }

      const submissionsRatings = ratings.filter(r => r.submissionId === submission.id);
      submission.ratings.push(...submissionsRatings);
    }

    for (const raterId of params.userIds) {
      const raterStats = stats.value?.find(s => s.raterId === raterId);

      if (raterStats === undefined) {
        throw new Error('Could not find the stats for the provided rater ID');
      }

      raterStats.assignedSubmissions += params.submissionIds.length;
      raterStats.pendingSubmissions += params.submissionIds.length;
    }
  });

  const unassignRater = asyncTask(async (ratingId: string, submissionId: string) => {
    await apiClient.deleteRating(ratingId);
    const submission = submissions.value?.find(s => s.id === submissionId);

    if (submission === undefined) {
      throw new Error('Could not find the submission with the provided ID');
    }

    const index = submission.ratings?.findIndex(r => r.id === ratingId);

    if (index === undefined) {
      throw new Error('Could not find the rating with the provided rating ID');
    }

    const rating = submission.ratings?.[index];
    const raterStats = stats.value?.find(s => s.raterId === rating?.userId);

    if (raterStats === undefined) {
      throw new Error('Could not find the stats for the provided rater ID');
    }

    submission.ratings?.splice(index, 1);
    raterStats.assignedSubmissions--;
    raterStats.pendingSubmissions--;
  });

  function updateRaterStats(
    submission: Submission,
    ratingId: string,
    newRaterStats: RaterStats
  ) {
    if (submission.ratings === undefined) {
      throw new Error('Could not find ratings for this submission');
    }

    const previousRating = submission.ratings.find(r => r.id === ratingId);

    if (previousRating === undefined) {
      throw new Error('Could not find rating');
    }

    const previousRaterStats = stats.value?.find(
      s => s.raterId === previousRating.userId
    );

    if (previousRaterStats === undefined) {
      throw new Error('Could not find the stats for the provided previous rater ID');
    }

    previousRaterStats.assignedSubmissions--;
    previousRaterStats.ratedSubmissions--;
    newRaterStats.assignedSubmissions++;
    newRaterStats.ratedSubmissions++;
  }

  const scoreRating = asyncTask(
    async (params: {
      rating: DeepReadonly<Rating>;
      score: number;
      comment: string | null;
    }) => {
      const submission = submissions.value?.find(
        s => s.id === params.rating.submissionId
      );

      if (submission === undefined) {
        throw new Error('Could not find the submission with the provided ID');
      }

      if (submission.ratings === undefined) {
        throw new Error('Could not find ratings for this submission');
      }

      if (auth.user === null) {
        throw new Error('Could not find authenticated user');
      }

      const rating = submission.ratings.find(r => r.id === params.rating.id);
      if (rating === undefined) {
        throw new Error('Could not find the rating with the provided rating id');
      }

      await apiClient.scoreRating(params.rating.id, {
        score: params.score,
        comment: params.comment
      });

      if (rating.userId === auth.user.id && rating.score === null) {
        if (stats.value !== null) {
          const newRaterStats = stats.value?.find(s => s.raterId === auth.user?.id);

          if (newRaterStats === undefined) {
            throw new Error('Could not find the stats for the provided new rater ID');
          }

          if (params.rating.score !== null) {
            updateRaterStats(submission, params.rating.id, newRaterStats);
          } else {
            newRaterStats.pendingSubmissions--;
            newRaterStats.ratedSubmissions++;
          }
        }
      } else {
        rating.lastEditedByUserId = auth.user.id;
      }

      rating.comment = params.comment;
      rating.score = params.score;
    }
  );

  const createScoredRating = asyncTask(
    async (params: { submissionId: string; score: number; comment: string | null }) => {
      if (auth.user === null) {
        throw new Error('Could not find authenticated user');
      }
      if (currentSchoolId.value === null || currentTestId.value === null) {
        throw new Error('Current school and test id are missing');
      }
      const ratings = await apiClient.createRating({
        schoolId: currentSchoolId.value,
        testId: currentTestId.value,
        score: params.score,
        comment: params.comment,
        submissionIds: [params.submissionId],
        userIds: [auth.user.id]
      });
      const submission = submissions.value?.find(s => s.id === params.submissionId);
      if (submission === undefined) {
        throw new Error('Could not find the submission with the provided ID');
      }

      const raterStats = stats.value?.find(s => s.raterId === auth.user?.id);
      if (raterStats === undefined) {
        throw new Error('Could not find the stats for the provided rater ID');
      }

      if (submission.ratings === undefined) {
        submission.ratings = [];
      }

      submission.ratings.push(ratings[0]);
      raterStats.assignedSubmissions++;
      raterStats.ratedSubmissions++;
    }
  );

  return {
    schools,
    tests,
    currentUserPermissions,
    permissions,
    ratersWithPermissions,
    currentSchool,
    currentTest,
    currentSchoolTests,
    currentPermissions,
    resultsAvailableAt,
    areResultsAvailable,
    formattedResultsAvailableAt,
    programHasAccess,
    userHasAccess,
    useMultiReviewers,
    raters,
    stats,
    submissions,
    submissionsFilter,
    augmentedSubmissions,
    filteredSubmissions,
    selectSchool,
    selectTest,
    loadSchoolsAndTests,
    loadSubmissions,
    loadRaters,
    assignRaters,
    unassignRater,
    scoreRating,
    createScoredRating
  };
});
