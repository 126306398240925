<template>
  <div class="flex flex-col min-h-screen">
    <div>
      <nav class="nav">
        <div class="flex-none sr-only sm:not-sr-only">
          <img
            src="@/assets/images/snapshot-logo-nav.svg"
            :alt="t('snapshotLogo')"
            class="w-28 md:w-40 ml-4"
          />
        </div>
        <div
          class="flex items-center flex-grow"
          :class="!$route.meta.hideNavLinks ? 'justify-between' : 'justify-end'"
        >
          <ul
            v-if="!$route.meta.hideNavLinks"
            class="nav-list flex flex-col sm:flex-row flex-none"
          >
            <li data-cy="menu-item-applicants">
              <router-link :to="{ name: 'program.applicants' }">
                {{ t('applicants') }}
              </router-link>
            </li>
            <li v-if="isSchoolAdmin" data-cy="menu-item-reviewers">
              <router-link :to="{ name: 'program.reviewers' }">
                {{ t('reviewers') }}
              </router-link>
            </li>
          </ul>
          <div class="flex items-center text-blue-darkest">
            <div v-if="!$route.meta.hideNavLinks" class="insights-container mr-2 md:mr-6">
              <a
                :href="insightsUrl"
                class="insights-link"
                @click="
                  logEvent({
                    eventName: 'SS_NAV_BACK_TO_INSIGHTS',
                    userProperties: {
                      userID: auth.user?.id
                    },
                    eventProperties: { tab: routeName }
                  })
                "
              >
                <span>{{ t('goToInsights') }}</span>
              </a>
            </div>
            <p class="mr-2 md:mr-6 lg:mr-10">
              <span class="sr-only">{{ t('srCurrentUser') }}</span>
              {{ userName }}
            </p>
          </div>
        </div>
      </nav>
    </div>
    <div class="mb-24">
      <router-view />
    </div>
    <div v-if="!$route.meta.hideFooter" class="mt-auto">
      <Footer language-selector />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { programStore } from '@/store/program';
import { useI18n } from '@/services/i18n';
import Footer from '@/components/common/Footer.vue';
import { logEvent } from '@/services/amplitude';
import { PendoUserType } from '@/services/pendo';

export default defineComponent({
  name: 'ProgramLayout',
  components: {
    Footer
  },
  setup() {
    const route = useRoute();

    const auth = useStore(authStore);
    const program = useStore(programStore);
    const routeName = computed(() => {
      if (route.name !== undefined && route.name !== null) {
        return (route.name as string).split('.')[1];
      }
      return '';
    });
    const userName = computed(() => auth.userName);
    const isSchoolAdmin = computed(() => auth.isSchoolAdmin);

    const insightsUrl = process.env.VUE_APP_ALTUS_INSIGHTS_URL;

    onMounted(async () => {
      await Promise.all([
        auth.launchSupportWidget('program'),
        auth.launchPendoTracker(PendoUserType.PROGRAM),
        program.loadSchoolsAndTests.run()
      ]);
    });

    return {
      userName,
      isSchoolAdmin,
      t: useI18n().t,
      auth,
      logEvent,
      routeName,
      insightsUrl
    };
  }
});
</script>

<style lang="postcss" scoped>
.nav {
  @apply flex justify-between items-center min-h-16 border-b-2 border-gray-lighter shadow-sm;
}

.nav-list {
  @apply ml-4 md:ml-10;
  & li {
    @apply mr-4 md:mr-6 lg:mr-10 py-5;

    & a {
      @apply py-5 transition-colors duration-300;

      &:hover {
        @apply text-gray;
      }

      &:focus-visible {
        @apply outline-none ring-2 ring-blue ring-offset-2;
      }

      /* Styling does not apply unless :moz-focusring is separated from :focus-visible, which is why it is repeated below */
      &:-moz-focusring {
        @apply outline-none ring-2 ring-blue ring-offset-2;
      }

      &:active {
        @apply text-blue;
      }
    }
  }
}

.insights-container {
  @apply border-gray-light border rounded-md;

  & .insights-link {
    @apply flex items-center justify-center py-2 px-4;

    &:focus {
      @apply outline-none ring-2 ring-blue rounded-md;
    }

    &:hover {
      @apply bg-gray-lighter rounded-md;
    }

    & img {
      @apply w-6;
    }
  }
}

.router-link-active {
  @apply text-blue cursor-default;
  box-shadow: rgb(77, 84, 160) 0 -0.2rem 0 inset;
}
</style>

<i18n>
{
  "en": {
    "applicants": "Applicants",
    "goToInsights": "Go to Insights",
    "reviewers": "Reviewers",
    "snapshotLogo": "Snapshot logo",
    "srCurrentUser": "Current user"
  },
  "fr": {
    "applicants": "Candidats",
    "goToInsights": "Explorer Insights",
    "reviewers": "Examinateurs",
    "snapshotLogo": "Logo Snapshot",
    "srCurrentUser": "Utilisateur actuel"
  }
}
</i18n>
