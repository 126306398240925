
import { computed, defineComponent, watchEffect } from 'vue';

export default defineComponent({
  name: 'AButton',
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string) => ['primary', 'outline', 'danger'].includes(value)
    },
    disabled: Boolean,
    to: null,
    link: Boolean
  },
  setup(props) {
    watchEffect(() => {
      if (props.link && props.disabled) {
        // eslint-disable-next-line no-console
        console.error(
          '[AButton]: Using link mode and disabled at the same time ' +
            'is not supported because of accessibility issues, and will' +
            'lead to wrong behavior. Remove "link" attribute.'
        );
      }
    });

    const classes = computed(() => ({
      primary: props.variant === 'primary',
      outline: props.variant === 'outline',
      danger: props.variant === 'danger',
      disabled: props.disabled
    }));

    return { classes };
  }
});
