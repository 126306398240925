
import { defineComponent, computed } from 'vue';
import { useI18n } from '@/services/i18n';

const variants: Record<string, string | undefined> = {
  primary: 'border-blue',
  secondary: 'border-teal',
  danger: 'border-red',
  success: 'border-green',
  warning: 'border-orange',
  info: 'border-babyblue',
  neutral: 'border-gray'
};

export default defineComponent({
  name: 'Spinner',
  props: {
    size: { type: String, default: '2rem' },
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string) => Object.keys(variants).includes(value)
    }
  },
  setup(props) {
    const getBorderColor = computed(() => {
      return variants[props.variant] ?? '';
    });

    return {
      getBorderColor,
      t: useI18n().t
    };
  }
});
