
import { authStore } from '@/store/auth';
import { useStore } from '@/store/lib/store';
import { computed, defineComponent, onMounted } from 'vue';
import { useI18n } from '@/services/i18n';
import Footer from '@/components/common/Footer.vue';
import { PendoUserType } from '@/services/pendo';

export default defineComponent({
  name: 'ApplicantLayout',
  components: {
    Footer
  },
  setup() {
    const auth = useStore(authStore);
    const userName = computed(() => auth.user?.profile?.name);
    const altusSuiteUrl = process.env.VUE_APP_ALTUS_SUITE_URL;

    onMounted(async () => {
      await Promise.all([
        auth.launchSupportWidget('applicant'),
        auth.launchPendoTracker(PendoUserType.APPLICANT)
      ]);
    });

    return {
      userName,
      t: useI18n().t,
      altusSuiteUrl
    };
  }
});
