import { RouteRecordRaw } from 'vue-router';
import ApplicantLayout from '@/views/applicant/ApplicantLayout.vue';
import { Guards } from './guards';

export default function generateApplicantRoutes(guards: Guards): RouteRecordRaw[] {
  return [
    {
      path: '/applicant',
      name: 'applicant',
      meta: { section: 'applicant' },
      component: ApplicantLayout,
      redirect: { name: 'applicant.selectTest' },
      children: [
        {
          path: 'select-test',
          name: 'applicant.selectTest',
          meta: { title: 'Select Snapshot', hideNavLinks: true, showFooter: true },
          component: () =>
            import(
              /* webpackChunkName: "applicant-select-test" */ '@/views/applicant/ApplicantSelectTest.vue'
            )
        },
        {
          path: 'home',
          name: 'applicant.home',
          meta: { title: 'Home', showFooter: true },
          beforeEnter: guards.requireApplicantTestSelected,
          component: () =>
            import(
              /* webpackChunkName: "applicant-home" */ '@/views/applicant/ApplicantHome.vue'
            )
        },
        {
          path: 'system-check',
          name: 'applicant.systemCheck',
          meta: { title: 'System Check' },
          beforeEnter: guards.requireApplicantTestSelected,
          component: () =>
            import(
              /* webpackChunkName: "applicant-system-check" */ '@/views/applicant/ApplicantSystemCheck.vue'
            )
        },
        {
          path: 'practice',
          name: 'applicant.practiceTest',
          meta: { title: 'Practice Snapshot' },
          beforeEnter: guards.requireApplicantTestSelected,
          component: () =>
            import(
              /* webpackChunkName: "applicant-practice-test" */ '@/views/applicant/ApplicantPracticeTest.vue'
            )
        },
        {
          path: 'test',
          name: 'applicant.test',
          meta: {
            title: 'Snapshot Interview',
            hideNavLinks: true,
            hideAltusSuiteButton: true
          },
          beforeEnter: guards.requireReadyForTest,
          component: () =>
            import(
              /* webpackChunkName: "applicant-test" */ '@/views/applicant/ApplicantTest.vue'
            )
        },
        {
          path: 'troubleshooting',
          name: 'applicant.troubleshooting',
          meta: {
            title: 'Troubleshooting Tips',
            hideNavLinks: true,
            hideAltusSuiteButton: true
          },
          component: () =>
            import(
              /* webpackChunkName: "troubleshooting" */ '@/views/applicant/Troubleshooting.vue'
            )
        }
      ]
    }
  ];
}
