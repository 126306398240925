import { render } from "./ProgramLayout.vue?vue&type=template&id=a6351302&scoped=true"
import script from "./ProgramLayout.vue?vue&type=script&lang=ts"
export * from "./ProgramLayout.vue?vue&type=script&lang=ts"

import "./ProgramLayout.vue?vue&type=style&index=0&id=a6351302&lang=postcss&scoped=true"
script.render = render
script.__scopeId = "data-v-a6351302"
/* custom blocks */
import block0 from "./ProgramLayout.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


export default script