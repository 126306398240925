import { DirectiveBinding, ObjectDirective } from 'vue';

const listeners = new WeakMap<HTMLElement, EventListener>();

async function mounted(el: HTMLElement, binding: DirectiveBinding) {
  const listener: EventListener = event => {
    if (
      el !== event.target &&
      !el.contains(((event as unknown) as { target: HTMLElement }).target)
    ) {
      binding.value(event);
    }
  };
  listeners.set(el, listener);
  await new Promise(r => setTimeout(r, 0));
  document.documentElement.addEventListener('click', listener, true);
}

function beforeUnmount(el: HTMLElement) {
  const listener = listeners.get(el);
  if (listener !== undefined) {
    document.documentElement.removeEventListener('click', listener, true);
  }
  listeners.delete(el);
}

const clickAway: ObjectDirective = {
  mounted,
  beforeUnmount,
  async updated(el, binding) {
    beforeUnmount(el);
    await mounted(el, binding);
  }
};

export default clickAway;
