import { computed, Ref, nextTick } from 'vue';
import { SupportConfig } from '@/interfaces/interfaces';
import { Section } from '@/router/guards';

export const timerReflectionThreshold = 5000;
export const timerAnswerThreshold = 20000;
const bigInteger = 1e9;

export const codec = 'video/webm;codecs="vp8,opus"';

export function randomId() {
  return Math.floor(Math.random() * bigInteger);
}

export function isMediaRecorderSupported() {
  return (
    navigator.mediaDevices !== undefined &&
    navigator.mediaDevices.getUserMedia !== undefined &&
    window.MediaRecorder !== undefined &&
    window.MediaRecorder.isTypeSupported(codec)
  );
}

export async function scrollToBottomOfPage() {
  await nextTick();
  window.scrollTo({ behavior: 'smooth', top: document.body.scrollHeight });
}

export function zeroPad(num: number) {
  return num < 10 ? `0${num}` : num.toString();
}

export function formatCountdownTime(time = 0) {
  const minutes = Math.floor(time / 1000 / 60);
  const seconds = Math.floor(time / 1000) - minutes * 60;
  return { minutes: zeroPad(minutes), seconds: zeroPad(seconds) };
}

export function formatDate(date: string, locale: string) {
  const dateLocale = locale === 'fr' ? 'fr-CA' : 'en-US';
  return new Date(date).toLocaleString(dateLocale, {
    weekday: 'short',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  });
}

export function formatLongDate(date: Date, locale: string) {
  const dateLocale = locale === 'fr' ? 'fr-CA' : 'en-US';
  const timePart = date.toLocaleString(dateLocale, {
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short',
    formatMatcher: 'basic'
  });
  const datePart = date.toLocaleString(dateLocale, {
    month: 'long',
    day: 'numeric',
    year: 'numeric'
  });
  return locale === 'fr' ? `${datePart} à ${timePart}` : `${timePart} on ${datePart}`;
}

export function formatCsvDate(date: string) {
  // en-US is hardcoded since we are formatting the date to be in numeric values
  const formattedDate = new Date(date).toLocaleString('en-US', {
    hour12: false,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  });
  // Removing the comma so excel/sheets can recognize it and format it as a date
  return formattedDate.replace(',', '');
}

export function downloadFile(content: string, fileName: string, mimeType = 'text/plain') {
  const a = document.createElement('a');
  a.href = `data:${mimeType};charset=utf-8,${encodeURIComponent(content)}`;
  a.download = fileName || 'download';
  a.click();
}

export async function setupSupportWidget(section: Section, config: SupportConfig) {
  if (section === 'admin') {
    throw new Error('Support widget not supported inside admin section');
  }
  // eslint-disable-next-line @typescript-eslint/camelcase
  config.app_name =
    section === 'applicant'
      ? 'snapshot_applicants'
      : section === 'program' || section === 'rater'
      ? 'snapshot_programs'
      : '';

  window.intercomSettings = config;
  const ic = window.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', window.intercomSettings);
  } else {
    const i = (((...args: unknown[]) => {
      i.c(args);
    }) as unknown) as Intercom_.IntercomProxy;
    i.q = [];
    i.c = (args: unknown[]) => {
      i.q.push(args);
    };
    i.booted = false;
    window.Intercom = i as never;
    const load = () => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = 'https://widget.intercom.io/widget/' + config.app_id;
      const firstScript = document.getElementsByTagName('script')[0];
      if (firstScript === null || firstScript.parentNode === null) {
        throw new Error('Did not find insert location');
      }
      firstScript.parentNode.insertBefore(script, firstScript);
    };
    const win: Window & { attachEvent?(event: string, handler: Function): void } = window;
    if (document.readyState === 'complete') {
      load();
    } else if (win.attachEvent) {
      win.attachEvent('onload', load);
    } else {
      window.addEventListener('load', load, false);
    }
  }
}

export function debounce<ParamType extends unknown>(
  fn: (...args: ParamType[]) => void,
  delay = 0,
  immediate = false
) {
  let timeout: number;

  return (...args: ParamType[]) => {
    if (immediate && !timeout) {
      fn(...args);
    }
    clearTimeout(timeout);

    timeout = window.setTimeout(() => {
      fn(...args);
    }, delay);
  };
}

export function debounceRef<ValueType extends unknown>(
  originalRef: Ref<ValueType>,
  delay: number,
  immediate?: boolean
) {
  return computed<ValueType>({
    get() {
      return originalRef.value;
    },
    set: debounce<ValueType>(
      value => {
        originalRef.value = value;
      },
      delay,
      immediate
    )
  });
}
