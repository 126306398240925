<template>
  <div class="overflow-x-auto">
    <table
      class="acuity-table w-full text-sm text-left"
      :role="data.length >= 1 ? 'table' : 'presentation'"
    >
      <thead>
        <tr
          class="border-b border-t border-gray-lighter text-gray-dark font-bold hover:bg-gray-lighter"
        >
          <th
            scope="col"
            v-for="col in columns"
            :key="typeof col === 'string' ? col : col.name"
          >
            <slot :name="'header-' + (typeof col === 'string' ? col : col.name)">
              {{
                typeof col === 'string'
                  ? col
                  : col.label !== undefined
                  ? col.label
                  : col.name
              }}
            </slot>
          </th>
        </tr>
      </thead>
      <tbody v-if="data.length >= 1">
        <tr
          class="border-b border-gray-lighter text-blue-darkest hover:bg-blue-lightest"
          v-for="(row, index) in data"
          :key="getKey(row, index)"
        >
          <td v-for="col in columns" :key="typeof col === 'string' ? col : col.name">
            <slot
              :name="'cell-' + (typeof col === 'string' ? col : col.name)"
              :row="row"
              :index="index"
            >
              {{ typeof col === 'string' ? row[col] : row[col.name] }}
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="data.length < 1"
      class="w-full lg:w-3/4 xl:w-3/5 2xl:w-1/2 mx-auto mt-20 mb-20"
    >
      <ChartMessage>
        <h2 class="w-3/4 mx-auto">
          {{ t('noDataAvailableTitle') }}
        </h2>
        <template #description>
          <p class="w-3/4 mx-auto">{{ t('noDataAvailableSubtitle') }}</p>
        </template>
      </ChartMessage>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from '@vue/runtime-core';
import { useI18n } from '@/services/i18n';
import ChartMessage from '@/components/program/ChartMessage.vue';

export default defineComponent({
  name: 'ATable',
  components: {
    ChartMessage
  },
  props: {
    columns: {
      type: Array as PropType<Array<string | { name: string; label: string }>>,
      required: true
    },
    data: { type: Array as PropType<Array<Record<string, unknown>>>, required: true },
    rowKey: { type: String, required: false }
  },
  setup(props) {
    function getKey(row: Record<string, unknown>, index: number) {
      if (props.rowKey !== undefined) {
        return row[props.rowKey] as string;
      }
      return index;
    }

    return {
      t: useI18n().t,
      getKey
    };
  }
});
</script>

<style lang="postcss" scoped>
.acuity-table {
  & td,
  & th {
    @apply py-4 px-4;
  }
}
</style>

<i18n>
{
  "en": {
    "noDataAvailableSubtitle": "We cannot find any results based on the combination you have selected, please try a different combination.",
    "noDataAvailableTitle": "No data available to display"
  },
  "fr": {
    "noDataAvailableSubtitle": "Nous ne trouvons aucun résultat à partir de la combinaison d'options que vous avez sélectionnées, veuillez essayer une combinaison différente.",
    "noDataAvailableTitle": "Aucune donnée disponible"
  }
}
</i18n>
