import { render } from "./CollapsibleCard.vue?vue&type=template&id=55629ad6&scoped=true"
import script from "./CollapsibleCard.vue?vue&type=script&lang=ts"
export * from "./CollapsibleCard.vue?vue&type=script&lang=ts"

import "./CollapsibleCard.vue?vue&type=style&index=0&id=55629ad6&lang=postcss&scoped=true"
script.render = render
script.__scopeId = "data-v-55629ad6"
/* custom blocks */
import block0 from "./CollapsibleCard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


export default script