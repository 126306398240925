
import { defineComponent } from 'vue';
import { randomId } from '@/services/util';

export default defineComponent({
  name: 'Select',
  inheritAttrs: false,
  props: {
    id: { type: String, default: () => `select-id-${randomId()}` },
    modelValue: null,
    label: { type: String },
    disabled: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function onChange(event: Event) {
      const value = (event.target as HTMLSelectElement).value;
      emit('update:modelValue', value);
    }

    return {
      onChange
    };
  }
});
