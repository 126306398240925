
import { defineComponent } from 'vue';
import { randomId } from '@/services/util';

export default defineComponent({
  name: 'RadioButton',
  inheritAttrs: false,
  props: {
    id: { type: String, default: () => `radio-id-${randomId()}` },
    modelValue: null,
    value: null
  },
  emits: ['update:modelValue']
});
