import { useI18n } from '@/services/i18n';

export function getLangName(lang: string) {
  const i18n = useI18n({ useScope: 'global' });
  return lang === 'en' ? i18n.t('en') : i18n.t('fr');
}

export function getLangOwnName(lang: string) {
  const i18n = useI18n({ useScope: 'global' });
  return lang === 'en'
    ? i18n.t('en', 'English', { locale: 'en' })
    : i18n.t('fr', 'Français', { locale: 'fr' });
}
