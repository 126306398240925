<template>
  <router-link
    v-if="to !== undefined"
    :to="to"
    :tag="link ? 'a' : 'button'"
    class="a-button"
    :class="classes"
    :disabled="disabled"
    v-bind="$attrs"
  >
    <slot />
  </router-link>
  <a v-else-if="link" class="a-button" :class="classes" v-bind="$attrs">
    <slot />
  </a>
  <button v-else class="a-button" :class="classes" :disabled="disabled" v-bind="$attrs">
    <slot />
  </button>
</template>

<script lang="ts">
import { computed, defineComponent, watchEffect } from 'vue';

export default defineComponent({
  name: 'AButton',
  inheritAttrs: false,
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string) => ['primary', 'outline', 'danger'].includes(value)
    },
    disabled: Boolean,
    to: null,
    link: Boolean
  },
  setup(props) {
    watchEffect(() => {
      if (props.link && props.disabled) {
        // eslint-disable-next-line no-console
        console.error(
          '[AButton]: Using link mode and disabled at the same time ' +
            'is not supported because of accessibility issues, and will' +
            'lead to wrong behavior. Remove "link" attribute.'
        );
      }
    });

    const classes = computed(() => ({
      primary: props.variant === 'primary',
      outline: props.variant === 'outline',
      danger: props.variant === 'danger',
      disabled: props.disabled
    }));

    return { classes };
  }
});
</script>

<style lang="postcss" scoped>
.a-button {
  @apply rounded py-2 px-6 text-base font-normal;
  @apply transition duration-300 cursor-pointer;
  &:focus {
    @apply outline-none ring-2;
  }

  &.primary {
    @apply bg-blue border border-blue text-white;

    &:hover,
    &:focus {
      @apply bg-blue-light border-blue-light;
    }

    &:focus {
      @apply ring-blue-dark;
    }

    &:active {
      @apply bg-blue-dark;
    }
  }

  &.danger {
    @apply bg-red border border-red text-white;

    &:hover,
    &:focus {
      @apply bg-red-light border border-red-light;
    }

    &:focus {
      @apply ring-blue-dark;
    }

    &:active {
      @apply bg-red-dark border border-red-dark;
    }
  }

  &.outline {
    @apply border border-gray text-blue-darkest;

    &:hover,
    &:focus {
      @apply bg-gray-lighter;
    }

    &:focus {
      @apply border-gray-lighter ring-blue-darkest;
    }
    &:active {
      @apply bg-gray-light ring-0 border-gray;
    }
  }

  &.disabled {
    @apply border border-gray-light;
    @apply bg-gray-lighter text-gray-light cursor-not-allowed;

    &:hover,
    &:focus {
      @apply border border-gray-light;
      @apply bg-gray-lighter text-gray-light;
    }
  }
}
</style>
