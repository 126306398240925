import { App } from 'vue';
import CollapsibleCard from './CollapsibleCard.vue';
import FixedCard from './FixedCard.vue';
import Button from './Button.vue';
import ProgressBar from './ProgressBar.vue';
import Alert from './Alert.vue';
import Spinner from './Spinner.vue';
import RadioButton from './RadioButton.vue';
import Chevron from './Chevron.vue';
import CardLink from './CardLink.vue';
import Header from './Header.vue';
import Arrow from './Arrow.vue';
import Card from './Card.vue';
import Checkbox from './Checkbox.vue';
import Table from './Table.vue';
import Input from './Input.vue';
import Select from './Select.vue';
import DownloadIcon from './DownloadIcon.vue';
import PlusIcon from './PlusIcon.vue';
import EditIcon from './EditIcon.vue';

import clickAway from './clickAway';

export default function loadGlobalComponents(app: App) {
  app.component('a-collapsible-card', CollapsibleCard);
  app.component('a-fixed-card', FixedCard);
  app.component('a-button', Button);
  app.component('a-progress-bar', ProgressBar);
  app.component('a-alert', Alert);
  app.component('a-spinner', Spinner);
  app.component('a-radio-button', RadioButton);
  app.component('a-checkbox', Checkbox);
  app.component('a-input', Input);
  app.component('a-chevron', Chevron);
  app.component('a-card-link', CardLink);
  app.component('a-header', Header);
  app.component('a-arrow', Arrow);
  app.component('a-card', Card);
  app.component('a-table', Table);
  app.component('a-select', Select);
  app.component('a-download-icon', DownloadIcon);
  app.component('a-plus-icon', PlusIcon);
  app.component('a-edit-icon', EditIcon);

  app.directive('clickAway', clickAway);
}
