<template>
  <div>
    <label :for="id" class="block mb-1" v-if="$slots.label || label !== undefined">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div class="select-wrapper" :class="{ disabled }">
      <select
        :id="id"
        class="block"
        v-bind="$attrs"
        :disabled="disabled"
        @change="onChange($event)"
        :value="modelValue"
      >
        <slot />
      </select>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { randomId } from '@/services/util';

export default defineComponent({
  name: 'Select',
  inheritAttrs: false,
  props: {
    id: { type: String, default: () => `select-id-${randomId()}` },
    modelValue: null,
    label: { type: String },
    disabled: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function onChange(event: Event) {
      const value = (event.target as HTMLSelectElement).value;
      emit('update:modelValue', value);
    }

    return {
      onChange
    };
  }
});
</script>

<style lang="postcss" scoped>
.select-wrapper {
  min-width: 34rem;
  @apply w-full border border-gray-light rounded bg-white text-gray-darker;
  @apply cursor-pointer grid items-center;
  grid-template-areas: 'select';

  &.disabled {
    @apply cursor-not-allowed bg-gray-light text-gray-darker;
  }

  &::after {
    grid-area: select;
    content: '';
    background-image: url('../../assets/icons/select-chevron.svg');
    @apply w-3 justify-self-end mr-3;
    height: 6px;
  }

  & > select {
    grid-area: select;
    @apply appearance-none w-full my-0 ml-0 py-2 px-3 mr-4 border-none rounded bg-transparent;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    outline: none;

    &:focus {
      @apply outline-none ring-2 ring-blue-lighter ring-offset-1;
    }
  }
}
</style>
