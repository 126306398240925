import { ref } from 'vue';
import { Role } from '../interfaces/interfaces';

interface PendoUser {
  visitor: {
    id?: string;
    role?: Role;
    isAltan?: boolean;
  };
  account: {
    id?: string;
  };
}

interface UpdatePendoUser {
  userId?: string;
  schoolId?: string;
  role?: Role;
  isAltan?: boolean;
}

export enum PendoUserType {
  APPLICANT = 'APPLICANT',
  PROGRAM = 'PROGRAM'
}

declare global {
  interface Window {
    pendo: {
      initialize?({ visitor, account }: PendoUser): void;
      identify({ visitor, account }: PendoUser): void;
    };
  }
}

export function initializePendoUser(data: UpdatePendoUser): void {
  if (window?.pendo?.initialize) {
    window.pendo.initialize({
      visitor: {
        id: data.userId,
        role: data.role,
        isAltan: data.isAltan
      },
      account: {
        id: data.schoolId
      }
    });
  }
}

export function updatePendoUser(data: UpdatePendoUser): void {
  window.pendo.identify({
    visitor: {
      id: data.userId,
      role: data.role,
      isAltan: data.isAltan
    },
    account: {
      id: data.schoolId
    }
  });
}

async function loadPendo(pendoApiKey: string, callback: () => void) {
  return new Promise<void>((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (function(window: any, document, o?: any) {
      const appName = 'pendo';
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let w: any;
      o = window[appName] = window[appName] || {};
      o._q = o._q || [];
      const v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      (function(m) {
        o[m] =
          o[m] ||
          function() {
            o._q[m === v[0] ? 'unshift' : 'push'](
              // eslint-disable-next-line prefer-rest-params
              [m].concat([].slice.call(arguments, 0))
            );
          };
      })(v[w]);
      const y = document.createElement('script') as HTMLScriptElement;
      y.async = true;
      y.src = 'https://cdn.pendo.io/agent/static/' + pendoApiKey + '/pendo.js';
      y.onload = () => {
        resolve();
        callback();
      };
      y.onerror = () => reject('Pendo script failed to load');
      const z = document.getElementsByTagName('script')[0];
      z?.parentNode?.insertBefore(y, z);
    })(window, document);
  });
}

export const pendoEnabled = {
  program: process.env.VUE_APP_PENDO_ENABLED_PROGRAM === 'true',
  applicant: process.env.VUE_APP_PENDO_ENABLED_APPLICANT === 'true'
};

export async function pendoInit(userType: PendoUserType, pendoCallback: () => void) {
  if (userType === PendoUserType.PROGRAM && pendoEnabled.program) {
    if (process.env.VUE_APP_PENDO_APP_ID_PROGRAM === undefined) {
      throw new Error('Missing required env var VUE_APP_PENDO_APP_ID_PROGRAM');
    }
    await loadPendo(process.env.VUE_APP_PENDO_APP_ID_PROGRAM, pendoCallback);
    return true;
  } else if (userType === PendoUserType.APPLICANT && pendoEnabled.applicant) {
    if (process.env.VUE_APP_PENDO_APP_ID_APPLICANT === undefined) {
      throw new Error('Missing required env var VUE_APP_PENDO_APP_ID_APPLICANT');
    }
    await loadPendo(process.env.VUE_APP_PENDO_APP_ID_APPLICANT, pendoCallback);
    return true;
  }
  return false;
}
