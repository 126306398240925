<template>
  <input
    type="checkbox"
    class="input"
    :id="id"
    v-bind="$attrs"
    v-model="checked"
    @change="onChange"
    :disabled="disabled"
  />
  <label :for="id" class="checkbox-label" :class="{ disabled }">
    <div class="checkbox" :class="{ checked, disabled }">
      <Checkmark aria-hidden="true" v-if="checked" class="checkmark" />
    </div>
    <slot />
  </label>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue';
import Checkmark from '@/components/common/Checkmark.vue';
import { randomId } from '@/services/util';

export default defineComponent({
  name: 'Checkbox',
  inheritAttrs: false,
  components: { Checkmark },
  props: {
    id: { type: String, default: () => `checkbox-id-${randomId()}` },
    modelValue: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const checked = ref(false);

    watchEffect(() => (checked.value = props.modelValue));

    function onChange(event: Event) {
      const checked = (event.target as HTMLInputElement).checked;
      emit('update:modelValue', checked);
    }

    return { onChange, checked };
  }
});
</script>

<style lang="postcss" scoped>
.input {
  @apply w-0.5 opacity-0;

  &:focus-visible + .checkbox-label .checkbox {
    @apply ring-blue-darker ring-2;
  }
}

.checkbox-label {
  @apply relative cursor-pointer pl-6 -ml-0.5 text-sm text-blue-darkest inline-block;

  &.disabled {
    @apply text-gray-light cursor-not-allowed;
  }

  &:hover:not(.disabled) .checkbox:not(.checked) {
    @apply border-blue bg-blue-lighter;
  }
}

.checkbox {
  @apply absolute left-0 w-4 h-4 border border-gray-light transition;
  border-radius: 0.185rem;
  @apply flex justify-center items-center;

  &.checked {
    @apply bg-blue border-blue;
  }

  &.disabled {
    @apply border-gray-light bg-gray-lighter;
    & .checkmark {
      @apply text-gray-light;
    }
  }
}

.checkmark {
  @apply text-white w-3 h-3;
}
</style>
