<template>
  <header class="orange-gradient" :class="{ dotted }">
    <slot />
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'Header',
  props: {
    dotted: Boolean
  }
});
</script>

<style lang="postcss" scoped>
.orange-gradient {
  background-image: linear-gradient(
    to bottom,
    #f26f3d 0%,
    #cb4e1e 39.17%,
    #a42c00 84.48%,
    #7f0000 108.29%
  );

  &.dotted {
    background-image: url('../../assets/images/header-dots.svg'),
      linear-gradient(
        to bottom,
        #f26f3d 0%,
        #cb4e1e 39.17%,
        #a42c00 84.48%,
        #7f0000 108.29%
      );
    @apply bg-cover bg-center;
  }
}
</style>
