
import { defineComponent } from 'vue';
import { randomId } from '@/services/util';

export default defineComponent({
  name: 'Input',
  inheritAttrs: false,
  props: {
    id: { type: String, default: () => `input-id-${randomId()}` },
    labelSrOnly: { type: Boolean, default: false },
    modelValue: null,
    label: { type: String },
    placeholder: { type: String }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function onInput(event: Event) {
      const value = (event.target as HTMLInputElement).value;
      emit('update:modelValue', value);
    }

    return {
      onInput
    };
  }
});
