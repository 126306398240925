import { createRouter, createWebHistory, Router, RouteRecordRaw } from 'vue-router';
import generateApplicantRoutes from './applicant';
import generateProgramRoutes from './program';
import { nextTick, App, inject, InjectionKey } from 'vue';
import { kickOut, setupGuards } from './guards';
import { useStore } from '@/store/lib/store';
import { authStore } from '@/store/auth';
import { injectToasts } from '@/components/common/toasts/toasts';
import { useI18n } from '@/services/i18n';

const key: InjectionKey<Router> = Symbol('router');

export function loadRouter(app: App) {
  const guards = setupGuards();

  const routes: RouteRecordRaw[] = [
    {
      path: '/auth/:section',
      name: 'auth',
      component: () => import(/* webpackChunkName: "auth" */ '@/views/Auth.vue'),
      props: route => ({
        section: route.params.section,
        accessToken: route.query.token
      })
    },
    ...generateApplicantRoutes(guards),
    ...generateProgramRoutes(guards)
  ];

  if (process.env.VUE_APP_SANDBOX_ENABLED === 'true') {
    routes.push({
      path: '/sandbox',
      name: 'sandbox',
      component: () => import(/* webpackChunkName: "sandbox" */ '@/views/Sandbox.vue')
    });
  }

  const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
  });

  const auth = useStore(authStore);

  let attemptedRestore = false;
  router.beforeEach(async (to, from, next) => {
    if (attemptedRestore) {
      return next();
    }
    if (to.name !== 'auth') {
      try {
        await auth.restoreSession();
      } catch {
        kickOut(to);
      }
    }
    attemptedRestore = true;
    next();
  });

  router.afterEach(async to => {
    await nextTick();
    document.title =
      (to.meta.title as string) ||
      (to.name && to.name.toString()) ||
      process.env.VUE_APP_NAME ||
      'frontend';
    window.scrollTo({ top: 0 });
  });

  const toasts = injectToasts();
  const i18n = useI18n({ useScope: 'global' });

  router.onError((error: Error) => {
    if (
      error.message.includes(`Couldn't resolve component "default" at`) &&
      navigator.onLine
    ) {
      return toasts.push({
        title: i18n.t('routerErrorTitle'),
        content: i18n.t('routerErrorSubtitle'),
        variant: 'danger',
        timeout: null
      });
    }
    throw error;
  });

  app.use(router).provide(key, router);
  return router;
}

export function injectRouter() {
  const router = inject(key);
  if (router === undefined) {
    throw new Error('Router instance is missing');
  }
  return router;
}
