<template>
  <div>
    <img src="@/assets/images/z-score-chart.svg" alt="" class="mx-auto" />
    <div class="mx-auto text-center mt-10">
      <div class="mb-6 px-6 font-bold text-3xl text-blue-darkest">
        <slot />
      </div>
      <slot name="description" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ChartMessage'
});
</script>
