
import { defineComponent, PropType } from '@vue/runtime-core';
import { useI18n } from '@/services/i18n';
import ChartMessage from '@/components/program/ChartMessage.vue';

export default defineComponent({
  name: 'ATable',
  components: {
    ChartMessage
  },
  props: {
    columns: {
      type: Array as PropType<Array<string | { name: string; label: string }>>,
      required: true
    },
    data: { type: Array as PropType<Array<Record<string, unknown>>>, required: true },
    rowKey: { type: String, required: false }
  },
  setup(props) {
    function getKey(row: Record<string, unknown>, index: number) {
      if (props.rowKey !== undefined) {
        return row[props.rowKey] as string;
      }
      return index;
    }

    return {
      t: useI18n().t,
      getKey
    };
  }
});
