import '@datadog/browser-logs/bundle/datadog-logs';
import { datadogRum } from '@datadog/browser-rum';

export function loadTracer() {
  if (process.env.VUE_APP_DATADOG_ENABLED === undefined) {
    throw new Error('Missing required VUE_APP_DATADOG_ENABLED');
  }

  if (process.env.VUE_APP_DATADOG_ENABLED === 'true') {
    if (process.env.VUE_APP_DATADOG_ENV === undefined) {
      throw new Error('Missing required VUE_APP_DATADOG_ENV');
    }

    if (process.env.VUE_APP_DATADOG_RUM_APP_ID === undefined) {
      throw new Error('Missing required VUE_APP_DATADOG_RUM_APP_ID');
    }

    if (process.env.VUE_APP_DATADOG_RUM_KEY === undefined) {
      throw new Error('Missing required VUE_APP_DATADOG_RUM_KEY');
    }

    // Will need to be added back wif we want to send logs in a more detailed way
    // window.DD_LOGS.init({
    //   clientToken: process.env.VUE_APP_DATADOG_KEY,
    //   env: process.env.VUE_APP_DATADOG_ENV,
    //   service: 'snapshot-frontend',
    //   forwardErrorsToLogs: true,
    //   sampleRate: 100
    // });

    datadogRum.init({
      applicationId: process.env.VUE_APP_DATADOG_RUM_APP_ID,
      clientToken: process.env.VUE_APP_DATADOG_RUM_KEY,
      site: 'datadoghq.com',
      env: process.env.VUE_APP_DATADOG_ENV,
      service: 'snapshot-frontend',
      sampleRate: 100,
      trackInteractions: true
    });
  }
}
