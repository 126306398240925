<template>
  <input
    :id="id"
    class="radio"
    type="radio"
    v-bind="$attrs"
    :checked="modelValue === value"
    @change="$emit('update:modelValue', value)"
  />
  <label :for="id" class="radio-label">
    <slot />
  </label>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { randomId } from '@/services/util';

export default defineComponent({
  name: 'RadioButton',
  inheritAttrs: false,
  props: {
    id: { type: String, default: () => `radio-id-${randomId()}` },
    modelValue: null,
    value: null
  },
  emits: ['update:modelValue']
});
</script>

<style lang="postcss" scoped>
.radio {
  @apply absolute opacity-0;
  &:focus,
  &:checked {
    & + .radio-label::before {
      @apply border-blue bg-transparent;
    }
  }

  &:checked + .radio-label::after {
    @apply bg-blue;
  }

  &:focus-visible + .radio-label::before {
    @apply ring-blue-darker ring-2;
  }

  &:disabled {
    & + .radio-label {
      @apply text-gray-light cursor-not-allowed;
    }
    & + .radio-label::before {
      @apply border-gray-light bg-gray-lighter;
    }
    &:checked + .radio-label::after {
      @apply bg-gray-light;
    }
  }
}
.radio-label {
  @apply relative cursor-pointer pl-6 text-sm text-blue-darkest;
  &::before,
  &::after {
    content: '';
    @apply absolute inset-x-0 inset-y-0 rounded-full;
  }

  &::before {
    @apply w-4 h-4 border border-gray-light;
  }

  &::after {
    @apply w-2 h-2 left-1 top-1 transition;
  }

  &:hover::before {
    @apply border-blue bg-blue-lighter;
  }
}
</style>
