<template>
  <div class="flex flex-col min-h-screen">
    <div>
      <nav class="nav">
        <div class="flex-none sr-only sm:not-sr-only">
          <img
            src="@/assets/images/snapshot-logo-nav.svg"
            :alt="t('snapshotLogo')"
            class="w-28 md:w-40 ml-4"
          />
        </div>
        <div
          class="flex items-center flex-grow"
          :class="!$route.meta.hideNavLinks ? 'justify-between' : 'justify-end'"
        >
          <ul
            v-if="!$route.meta.hideNavLinks"
            class="nav-list flex flex-col sm:flex-row flex-none"
          >
            <li>
              <router-link :to="{ name: 'applicant.home' }">
                {{ t('home') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'applicant.systemCheck' }">
                {{ t('systemCheck') }}
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'applicant.practiceTest' }">
                {{ t('practiceSnapshot') }}
              </router-link>
            </li>
          </ul>
          <div class="flex items-center text-blue-darkest">
            <div
              v-if="!$route.meta.hideAltusSuiteButton"
              class="altus-suite-container mr-2 md:mr-6"
            >
              <a :href="altusSuiteUrl" class="altus-suite-link">
                <img src="@/assets/icons/altus-logo.svg" alt="" class="mr-2" />
                <span>{{ t('backToMyAssessments') }}</span>
              </a>
            </div>
            <p class="mr-2 md:mr-6 lg:mr-10">
              <span class="sr-only">{{ t('srCurrentUser') }}</span>
              {{ userName }}
            </p>
          </div>
        </div>
      </nav>
    </div>
    <div class="mb-24">
      <router-view />
    </div>
    <div v-if="$route.meta.showFooter" class="mt-auto">
      <Footer />
    </div>
  </div>
</template>

<script lang="ts">
import { authStore } from '@/store/auth';
import { useStore } from '@/store/lib/store';
import { computed, defineComponent, onMounted } from 'vue';
import { useI18n } from '@/services/i18n';
import Footer from '@/components/common/Footer.vue';
import { PendoUserType } from '@/services/pendo';

export default defineComponent({
  name: 'ApplicantLayout',
  components: {
    Footer
  },
  setup() {
    const auth = useStore(authStore);
    const userName = computed(() => auth.user?.profile?.name);
    const altusSuiteUrl = process.env.VUE_APP_ALTUS_SUITE_URL;

    onMounted(async () => {
      await Promise.all([
        auth.launchSupportWidget('applicant'),
        auth.launchPendoTracker(PendoUserType.APPLICANT)
      ]);
    });

    return {
      userName,
      t: useI18n().t,
      altusSuiteUrl
    };
  }
});
</script>

<style lang="postcss" scoped>
.nav {
  @apply flex justify-between items-center min-h-16 border-b-2 border-gray-lighter shadow-sm;
}

.nav-list {
  @apply ml-4 md:ml-10;
  & li {
    @apply mr-4 md:mr-6 lg:mr-10 py-5;

    & a {
      @apply py-5 transition-colors duration-300;

      &:hover {
        @apply text-gray;
      }

      &:focus-visible {
        @apply outline-none ring-2 ring-blue ring-offset-2;
      }

      /* Styling does not apply unless :moz-focusring is separated from :focus-visible, which is why it is repeated below */
      &:-moz-focusring {
        @apply outline-none ring-2 ring-blue ring-offset-2;
      }

      &:active {
        @apply text-blue;
      }
    }
  }
}

.altus-suite-container {
  @apply border-gray-light border rounded-md;

  & .altus-suite-link {
    @apply flex items-center justify-center py-2 px-4;

    &:focus {
      @apply outline-none ring-2 ring-blue rounded-md;
    }

    &:hover {
      @apply bg-gray-lighter rounded-md;
    }
  }
}

.router-link-active {
  @apply text-blue cursor-default;
  box-shadow: rgb(77, 84, 160) 0 -0.2rem 0 inset;
}
</style>

<i18n>
{
  "en": {
    "backToMyAssessments": "Back to my assessments",
    "home": "Home",
    "practiceSnapshot": "Practice Snapshot",
    "snapshotLogo": "Snapshot logo",
    "srCurrentUser": "Current user",
    "systemCheck": "System Check"
  },
  "fr": {
    "backToMyAssessments": "Retourner à mes évaluations",
    "home": "Accueil",
    "practiceSnapshot": "Entraînement Snapshot",
    "snapshotLogo": "Logo de Snapshot",
    "srCurrentUser": "Utilisateur actuel",
    "systemCheck": "Vérification du système"
  }
}
</i18n>
