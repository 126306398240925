import { Role } from '@/interfaces/interfaces';
import { applicantStore } from '@/store/applicant';
import { authStore } from '@/store/auth';
import { useStore } from '@/store/lib/store';
import { programStore } from '@/store/program';
import { NavigationGuard, RouteLocationNormalized, RouteLocationRaw } from 'vue-router';

export const sections = ['applicant', 'program', 'admin'] as const;
export type Section = typeof sections[number];

export function getAppSection(route: RouteLocationNormalized): Section | undefined {
  for (const r of Array.from(route.matched).reverse()) {
    if (r.meta.section !== undefined && sections.includes(r.meta.section as Section)) {
      return r.meta.section as Section;
    }
  }
}

declare global {
  interface Window {
    replaceLocation(url: string): void;
  }
}
if (window.replaceLocation === undefined) {
  window.replaceLocation = (url: string) => window.location.replace(url);
}

export function kickOut(targetRoute: RouteLocationNormalized): void;
export function kickOut(section?: Section): void;
export function kickOut(sectionOrRoute?: Section | RouteLocationNormalized): void {
  const section =
    typeof sectionOrRoute === 'string' || sectionOrRoute === undefined
      ? sectionOrRoute
      : getAppSection(sectionOrRoute);
  if (
    process.env.VUE_APP_ALTUS_SUITE_URL === undefined ||
    process.env.VUE_APP_ALTUS_INSIGHTS_URL === undefined ||
    process.env.VUE_APP_ALTUS_ADMIN_URL === undefined
  ) {
    throw new Error('Missing required kickout URLs');
  }
  if (section === 'applicant') {
    window.replaceLocation(process.env.VUE_APP_ALTUS_SUITE_URL);
  } else if (section === 'program') {
    window.replaceLocation(process.env.VUE_APP_ALTUS_INSIGHTS_URL);
  } else if (section === 'admin') {
    window.replaceLocation(process.env.VUE_APP_ALTUS_ADMIN_URL);
  } else {
    // By default we kick out to Altus Suite
    window.replaceLocation(process.env.VUE_APP_ALTUS_SUITE_URL);
  }
}

export function setupGuards() {
  const auth = useStore(authStore);
  const applicant = useStore(applicantStore);
  const program = useStore(programStore);

  function requireRole(role: Role): NavigationGuard;
  function requireRole(roles: Role[]): NavigationGuard;
  function requireRole(role: Role | Role[]): NavigationGuard {
    const roles = typeof role === 'string' ? [role] : role;
    return to => {
      if (auth.user?.roles?.some(r => roles.includes(r))) {
        return true;
      }
      kickOut(to);
      return false;
    };
  }

  const requireApplicantTestSelected: NavigationGuard = () => {
    return applicant.currentTest !== null || { name: 'applicant.selectTest' };
  };

  const requireReadyForTest: NavigationGuard = () => {
    return applicant.isReadyForTest || { name: 'applicant.home' };
  };

  function requireProgramTestSelected(redirectTo: RouteLocationRaw): NavigationGuard {
    return () =>
      (program.currentSchool !== undefined && program.currentTest !== undefined) ||
      redirectTo;
  }

  return {
    requireRole,
    requireApplicantTestSelected,
    requireReadyForTest,
    requireProgramTestSelected
  };
}

export type Guards = ReturnType<typeof setupGuards>;
