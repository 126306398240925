export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "backToMyAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to my assessments"])},
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
        "practiceSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice Snapshot"])},
        "snapshotLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot logo"])},
        "srCurrentUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current user"])},
        "systemCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Check"])}
      },
      "fr": {
        "backToMyAssessments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à mes évaluations"])},
        "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
        "practiceSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entraînement Snapshot"])},
        "snapshotLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de Snapshot"])},
        "srCurrentUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur actuel"])},
        "systemCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du système"])}
      }
    }
  })
}
