<template>
  <div>
    <label
      :for="id"
      v-if="$slots.label || label !== undefined"
      class="block mb-2 font-bold"
      :class="{ 'sr-only': labelSrOnly }"
    >
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div
      class="py-2 px-3 flex items-center border border-gray-light rounded input-wrapper"
    >
      <div v-if="$slots.prepend">
        <slot name="prepend" />
      </div>
      <input
        v-bind="$attrs"
        :id="id"
        class="appearance-none flex-grow focus:outline-none"
        @input="onInput($event)"
        :value="modelValue"
        :placeholder="labelSrOnly && placeholder === undefined ? label : placeholder"
      />
      <div v-if="$slots.append">
        <slot name="append" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { randomId } from '@/services/util';

export default defineComponent({
  name: 'Input',
  inheritAttrs: false,
  props: {
    id: { type: String, default: () => `input-id-${randomId()}` },
    labelSrOnly: { type: Boolean, default: false },
    modelValue: null,
    label: { type: String },
    placeholder: { type: String }
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    function onInput(event: Event) {
      const value = (event.target as HTMLInputElement).value;
      emit('update:modelValue', value);
    }

    return {
      onInput
    };
  }
});
</script>

<style lang="postcss" scoped>
.input-wrapper:focus-within {
  box-shadow: 0 0 6px theme('colors.blue.DEFAULT');
}

.input-wrapper > input::placeholder {
  @apply text-gray-dark opacity-100;
}
</style>
