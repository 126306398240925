import { randomId } from '@/services/util';
import { App, inject, InjectionKey, provide, readonly, ref } from 'vue';

export interface ToastParams {
  title?: string;
  content?: string;
  variant?: 'danger' | 'success' | 'warning' | 'info';
  dismissible?: boolean;
  icon?: boolean;
  timeout?: number | null;
}

export interface Toast extends ToastParams {
  id: string;
  timeoutId: number | null;
}

export function createToastsManager() {
  const queue = ref<Toast[]>([]);

  function remove(toastId: string) {
    const index = queue.value.findIndex(t => t.id === toastId);
    const toast = queue.value[index];
    queue.value.splice(index, 1);
    if (toast.timeoutId !== null) {
      clearTimeout(toast.timeoutId);
    }
  }

  function push(params: ToastParams) {
    const id = `toast-${randomId()}`;
    const timeout = params.timeout !== undefined ? params.timeout : 5000;
    const timeoutId =
      timeout === null ? null : window.setTimeout(() => remove(id), timeout);
    const toast: Toast = {
      dismissible: true,
      icon: false,
      variant: 'info',
      ...params,
      id,
      timeout,
      timeoutId
    };
    queue.value.push(toast);
  }

  return readonly({
    queue,
    push,
    remove
  });
}

const key: InjectionKey<ReturnType<typeof createToastsManager>> = Symbol('toasts');

export function provideToasts(app?: App) {
  const toasts = createToastsManager();
  if (app !== undefined) {
    app.provide(key, toasts);
  } else {
    provide(key, toasts);
  }
  return toasts;
}

export function injectToasts() {
  const toasts = inject(key);
  if (toasts === undefined) {
    throw new Error('ToastsManager instance is missing');
  }
  return toasts;
}
