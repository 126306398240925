
import { defineComponent } from 'vue';
import { injectToasts } from './toasts';

export default defineComponent({
  name: 'ToastsContainer',
  setup() {
    return {
      toasts: injectToasts()
    };
  }
});
