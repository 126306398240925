
import { defineComponent, ref, watchEffect } from 'vue';
import { randomId } from '@/services/util';
import { useI18n } from '@/services/i18n';

export default defineComponent({
  name: 'CollapsibleCard',
  props: {
    expanded: { type: Boolean },
    'onUpdate:expanded': { type: Function },
    fixed: { type: Boolean }
  },
  setup(props, { emit }) {
    const expandedState = ref(props.expanded);
    const id = `card-id-${randomId()}`;

    watchEffect(() => {
      expandedState.value = props.expanded;
    });

    function toggleExpanded() {
      if (props.fixed) {
        return;
      }

      if (props['onUpdate:expanded'] !== undefined) {
        emit('update:expanded', !expandedState.value);
      } else {
        expandedState.value = !expandedState.value;
      }
    }

    const wrapperDiv = ref<HTMLElement | null>(null);
    const contentDiv = ref<HTMLElement | null>(null);

    function beforeEnter() {
      if (wrapperDiv.value) {
        wrapperDiv.value.style.height = '0';
      }
    }

    function enter() {
      if (wrapperDiv.value && contentDiv.value) {
        wrapperDiv.value.style.height = contentDiv.value.clientHeight + 'px';
      }
    }

    function afterEnter() {
      if (wrapperDiv.value) {
        wrapperDiv.value.style.height = 'auto';
      }
    }

    function beforeLeave() {
      if (wrapperDiv.value && contentDiv.value) {
        wrapperDiv.value.classList.remove('expand-transition');
        wrapperDiv.value.style.height = contentDiv.value.clientHeight + 'px';
      }
    }

    function leave() {
      if (wrapperDiv.value) {
        wrapperDiv.value.classList.add('expand-transition');
        wrapperDiv.value.style.height = '0';
      }
    }

    return {
      id,
      expandedState,
      toggleExpanded,
      wrapperDiv,
      contentDiv,
      beforeEnter,
      enter,
      afterEnter,
      beforeLeave,
      leave,
      t: useI18n().t
    };
  }
});
