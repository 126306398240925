import { reactive, ref, shallowRef } from 'vue';

export function asyncTask<ParamsType extends Array<unknown>, ResultType>(
  perform: (...args: ParamsType) => Promise<ResultType>
) {
  const isRunning = ref(false);
  const error = ref<null | unknown>(null);
  const value = shallowRef<ResultType | null>(null);

  let nonce = 0;

  async function run(...args: ParamsType) {
    nonce++;
    const currentNonce = nonce;
    isRunning.value = true;
    error.value = null;
    try {
      const data = await perform(...args);
      if (nonce === currentNonce) {
        value.value = data;
      }
    } catch (e) {
      if (nonce === currentNonce) {
        error.value = e;
      }
    }
    if (nonce === currentNonce) {
      isRunning.value = false;
    }
  }

  return reactive({
    run,
    isRunning,
    error,
    value
  });
}
