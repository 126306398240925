<template>
  <div class="py-8 px-9 border-2 border-gray-lighter rounded-lg shadow-lg">
    <div
      class="flex mx-2"
      :class="{ 'cursor-pointer': !fixed }"
      @click="toggleExpanded()"
    >
      <div class="text-xl flex-grow">
        <slot />
      </div>
      <button
        v-if="!fixed"
        class="chevron-button focus:outline-none"
        :aria-controls="id"
        :aria-expanded="expandedState"
      >
        <span class="sr-only">
          {{ expandedState ? t('srCollapse') : t('srExpand') }}
        </span>
        <img
          src="@/assets/icons/chevron.svg"
          class="mr-3 transform rotate-90 transition w-5"
          :class="{ '-rotate-90': expandedState }"
          alt=""
        />
      </button>
    </div>
    <div
      ref="wrapperDiv"
      class="overflow-hidden expand-transition"
      :class="{ 'h-0': !expandedState }"
    >
      <transition
        name="expand"
        @before-enter="beforeEnter()"
        @enter="enter()"
        @after-enter="afterEnter()"
        @before-leave="beforeLeave()"
        @leave="leave()"
      >
        <div v-show="expandedState" ref="contentDiv" class="mx-2 mt-4" :id="id">
          <slot name="content" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue';
import { randomId } from '@/services/util';
import { useI18n } from '@/services/i18n';

export default defineComponent({
  name: 'CollapsibleCard',
  props: {
    expanded: { type: Boolean },
    'onUpdate:expanded': { type: Function },
    fixed: { type: Boolean }
  },
  setup(props, { emit }) {
    const expandedState = ref(props.expanded);
    const id = `card-id-${randomId()}`;

    watchEffect(() => {
      expandedState.value = props.expanded;
    });

    function toggleExpanded() {
      if (props.fixed) {
        return;
      }

      if (props['onUpdate:expanded'] !== undefined) {
        emit('update:expanded', !expandedState.value);
      } else {
        expandedState.value = !expandedState.value;
      }
    }

    const wrapperDiv = ref<HTMLElement | null>(null);
    const contentDiv = ref<HTMLElement | null>(null);

    function beforeEnter() {
      if (wrapperDiv.value) {
        wrapperDiv.value.style.height = '0';
      }
    }

    function enter() {
      if (wrapperDiv.value && contentDiv.value) {
        wrapperDiv.value.style.height = contentDiv.value.clientHeight + 'px';
      }
    }

    function afterEnter() {
      if (wrapperDiv.value) {
        wrapperDiv.value.style.height = 'auto';
      }
    }

    function beforeLeave() {
      if (wrapperDiv.value && contentDiv.value) {
        wrapperDiv.value.classList.remove('expand-transition');
        wrapperDiv.value.style.height = contentDiv.value.clientHeight + 'px';
      }
    }

    function leave() {
      if (wrapperDiv.value) {
        wrapperDiv.value.classList.add('expand-transition');
        wrapperDiv.value.style.height = '0';
      }
    }

    return {
      id,
      expandedState,
      toggleExpanded,
      wrapperDiv,
      contentDiv,
      beforeEnter,
      enter,
      afterEnter,
      beforeLeave,
      leave,
      t: useI18n().t
    };
  }
});
</script>

<style lang="postcss" scoped>
.chevron-button {
  &:focus-visible {
    @apply outline-none;
  }

  &:focus-visible img {
    @apply ring-2 ring-blue;
  }
}

.expand-transition,
.expand-enter-active,
.expand-leave-active {
  transition: all 0.5s ease;
}

.expand-enter-from,
.expand-leave-to {
  transform: translateY(40%);
  opacity: 0;
}
</style>

<i18n>
{
  "en": {
    "srCollapse": "Collapse card",
    "srExpand": "Expand card"
  },
  "fr": {
    "srCollapse": "Réduire le menu",
    "srExpand": "Agrandir le menu"
  }
}
</i18n>
