export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Copyright © 2020-", _interpolate(_named("currentYear")), " Acuity Insights Inc."])}
      },
      "fr": {
        "copyright": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Droits d’auteur © 2020-", _interpolate(_named("currentYear")), " Acuity Insights Inc."])}
      }
    }
  })
}
