import { Test } from '@/interfaces/interfaces';
import { computed, ref } from 'vue';
import { defineStore } from './lib/store';

export const applicantStore = defineStore('applicant', () => {
  const currentTest = ref<Test | null>(null);
  const isSystemCheckDone = ref(false);
  const isPracticeDone = ref(false);

  const isReadyForTest = computed(() => {
    return currentTest.value !== null && isSystemCheckDone.value && isPracticeDone.value;
  });

  function markSystemCheckDone() {
    isSystemCheckDone.value = true;
  }

  function markPracticeDone() {
    isPracticeDone.value = true;
  }

  function selectTest(test: Test) {
    currentTest.value = test;
    isSystemCheckDone.value = false;
    isPracticeDone.value = false;
  }

  return {
    currentTest,
    isSystemCheckDone,
    isPracticeDone,
    isReadyForTest,
    markSystemCheckDone,
    markPracticeDone,
    selectTest
  };
});
