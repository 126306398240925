<template>
  <div class="fixed bottom-0 left-0 m-4">
    <transition-group name="toast">
      <div v-for="toast in toasts.queue" :key="toast.id" data-cy="toast-notification">
        <a-alert
          class="mt-2"
          :variant="toast.variant || 'info'"
          :dismissible="toast.dismissible"
          @dismissed="toasts.remove(toast.id)"
          :icon="toast.icon"
        >
          <h2 class="mb-2 text-gray-darker">{{ toast.title }}</h2>
          <p class="text-sm">{{ toast.content }}</p>
        </a-alert>
      </div>
    </transition-group>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { injectToasts } from './toasts';

export default defineComponent({
  name: 'ToastsContainer',
  setup() {
    return {
      toasts: injectToasts()
    };
  }
});
</script>

<style lang="postcss" scoped>
.toast-enter-active,
.toast-leave-active,
.toast-move {
  transition: all 0.6s ease;
}
.toast-enter-from,
.toast-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
</style>
