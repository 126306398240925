<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33301 3.16675H2.66634C2.31272 3.16675 1.97358 3.30722 1.72353 3.55727C1.47348 3.80732 1.33301 4.14646 1.33301 4.50008V13.8334C1.33301 14.187 1.47348 14.5262 1.72353 14.7762C1.97358 15.0263 2.31272 15.1667 2.66634 15.1667H11.9997C12.3533 15.1667 12.6924 15.0263 12.9425 14.7762C13.1925 14.5262 13.333 14.187 13.333 13.8334V9.16675"
      class="stroke-current"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.333 2.16665C12.5982 1.90144 12.9579 1.75244 13.333 1.75244C13.7081 1.75244 14.0678 1.90144 14.333 2.16665C14.5982 2.43187 14.7472 2.79158 14.7472 3.16665C14.7472 3.54173 14.5982 3.90144 14.333 4.16665L7.99967 10.5L5.33301 11.1667L5.99967 8.49999L12.333 2.16665Z"
      class="stroke-current"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
