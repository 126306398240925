<template>
  <div class="text-gray-darker text-base">
    <router-view />
    <ToastsContainer />
    <DefaultModal />
  </div>
</template>

<script lang="ts">
import { App, defineComponent, PropType, watchEffect } from 'vue';
import { loadI18n, useI18n } from './services/i18n';
import loadGlobalComponents from './components/global/loadGlobalComponents';
import { loadRouter } from './router';
import { kickOut } from './router/guards';
import { loadApiClient } from './services/apiClient';
import { loadTracer } from './services/tracer';

import ToastsContainer from './components/common/toasts/ToastsContainer.vue';
import { provideToasts } from './components/common/toasts/toasts';

import DefaultModal from './components/common/modal/DefaultModal.vue';
import { provideModals } from './components/common/modal/modal';

export default defineComponent({
  name: 'App',
  components: { ToastsContainer, DefaultModal },
  props: {
    getApp: { required: true, type: Function as PropType<() => App> }
  },
  setup(props) {
    loadTracer();
    const app = props.getApp();
    const toasts = provideToasts(app);
    loadI18n(app);
    const client = loadApiClient(app);
    const router = loadRouter(app);
    provideModals(app);
    loadGlobalComponents(app);

    // Store current locale to retrieve it in new tabs and other cases
    const i18n = useI18n({ useScope: 'local' });
    const globalI18n = useI18n({ useScope: 'global' });
    const savedLocale = window.localStorage.getItem('snapshotLocale');
    globalI18n.locale.value = savedLocale ?? globalI18n.fallbackLocale.value.toString();
    watchEffect(() => {
      window.localStorage.setItem('snapshotLocale', i18n.locale.value);
    });

    // Set up toasts for request errors
    client.addResponseErrorInterceptor(async error => {
      if (error.response.status === 401) {
        kickOut(router.currentRoute.value);
      } else if (error.response.status === 403) {
        toasts.push({
          title: i18n.t('accessDeniedTitle'),
          content: i18n.t('accessDeniedContent'),
          variant: 'warning',
          timeout: null
        });
      } else if (error.response.status === 500) {
        toasts.push({
          title: i18n.t('serverErrorTitle'),
          content: i18n.t('serverErrorContent'),
          variant: 'danger',
          timeout: null
        });
      }

      throw error;
    });
  }
});
</script>

<i18n>
{
  "en": {
    "accessDeniedContent": "You may not have a permission to access the requested resource",
    "accessDeniedTitle": "Access denied",
    "serverErrorContent": "Something went wrong so we couldn't fulfill your request. Please refresh the page or try again later.",
    "serverErrorTitle": "Unexpected server error"
  },
  "fr": {
    "accessDeniedContent": "Il est possible que vous ne soyez pas autorisé(e) à accéder à la ressource demandée",
    "accessDeniedTitle": "Accès refusé",
    "serverErrorContent": "Une erreur s'est produite et nous n'avons pas pu accéder à votre demande. Veuillez rafraîchir la page ou réessayer plus tard.",
    "serverErrorTitle": "Erreur de serveur inattendue"
  }
}
</i18n>
