
import { defineComponent, computed, ref } from 'vue';
import { useI18n } from '@/services/i18n';

import dangerIcon from '@/assets/icons/x-circle-solid.svg';
import successIcon from '@/assets/icons/check-circle-solid.svg';
import warningIcon from '@/assets/icons/exclamation-triangle.svg';
import infoIcon from '@/assets/icons/i-circle-solid.svg';

const variants: Record<string, { class: string; icon: string } | undefined> = {
  danger: {
    class: 'danger',
    icon: dangerIcon
  },
  success: {
    class: 'success',
    icon: successIcon
  },
  warning: {
    class: 'warning',
    icon: warningIcon
  },
  info: {
    class: 'info',
    icon: infoIcon
  }
};

export default defineComponent({
  name: 'Alert',
  props: {
    icon: { type: Boolean },
    variant: {
      type: String,
      default: 'info',
      validator: (value: string) => Object.keys(variants).includes(value)
    },
    dismissible: { type: Boolean },
    onDismissed: { type: Function, required: false }
  },
  emits: ['dismissed'],
  setup(props) {
    const dismissed = ref(false);

    const getVariant = computed(() => {
      return variants[props.variant] ?? variants.info;
    });

    return {
      dismissed,
      getVariant,
      t: useI18n().t
    };
  }
});
