
import { defineComponent, onMounted, onBeforeMount, reactive, readonly, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { injectModals, ModalHandler } from './modal';

export default defineComponent({
  name: 'Modal',
  props: {
    name: { type: String },
    dismissible: { type: Boolean, default: true },
    title: { type: String },
    content: { type: String },
    footer: { type: Boolean, default: true },
    acceptButton: { type: [String, Boolean], default: true },
    cancelButton: { type: [String, Boolean], default: true },
    width: {
      type: String,
      default: 'md',
      validator: (value: string) => ['sm', 'md', 'lg', 'xl'].includes(value)
    }
  },
  setup(props) {
    const modals = injectModals();

    const visible = ref(false);
    let promise = Promise.resolve(false);

    let resolve: null | ((result: boolean) => void) = null;

    function open() {
      visible.value = true;
      promise = new Promise(res => {
        resolve = res;
      });
      return promise;
    }

    function close(result: boolean) {
      visible.value = false;
      resolve?.(result);
    }

    function accept() {
      close(true);
    }

    function dismiss() {
      close(false);
    }

    onMounted(() => {
      if (typeof props.name === 'string') {
        const handler: ModalHandler = readonly(
          reactive({
            visible,
            open,
            close,
            accept,
            dismiss
          })
        );
        modals.registerModal(props.name, handler);
      }
    });
    onBeforeMount(() => {
      if (typeof props.name === 'string') {
        modals.unregisterModal(props.name);
      }
    });

    return {
      t: useI18n().t,
      visible,
      accept,
      dismiss,
      close
    };
  }
});
