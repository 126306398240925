
import { defineComponent } from 'vue';
import { useI18n } from '@/services/i18n';
import { getLangOwnName } from '@/services/langNames';
import { Language } from '@/interfaces/interfaces';

export default defineComponent({
  name: 'Footer',
  props: {
    languageSelector: { type: Boolean, default: false }
  },
  setup() {
    const i18n = useI18n();
    const i18nGlobal = useI18n({ useScope: 'global' });

    function selectLanguage(lang: Language) {
      i18nGlobal.locale.value = lang;
    }

    return {
      t: i18n.t,
      getLangOwnName,
      selectLanguage
    };
  }
});
