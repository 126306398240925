import amplitude from 'amplitude-js';
import { format } from 'date-fns';

// TODO Fix this to be a union type of different interfaces for each type of event:
export interface UserProperties {
  userID?: string;
}
export interface EventProperties {
  programSelection?: string;
  programSelectionName?: string;
  snapshotTestId?: string;
  snapshotTestVersion?: string;
  snapshotTestLanguage?: string;
  applicantUserId?: string;
  videoResponseQuestion?: number;
  isApplicantRated?: boolean;
  direction?: string;
  hasComment?: boolean;
  snapshotScore?: number;
  reviewerUserId?: string | string[];
  newSnapshotScore?: number;
  previousReviewerUserId?: string;
  searchValue?: string;
  tab?: string;
  timeSpent?: number;
  videoLength?: number;
  timeToRate?: number;
}

export interface AmplitudeArguments {
  eventName: string;
  userProperties?: UserProperties;
  eventProperties?: EventProperties;
}

export function initAmplitude(section: string, userId?: string) {
  if (process.env.VUE_APP_AMPLITUDE_ENABLED === undefined) {
    throw new Error('Missing required VUE_APP_AMPLITUDE_ENABLED');
  }

  if (process.env.VUE_APP_AMPLITUDE_ENABLED === 'true') {
    if (process.env.VUE_APP_AMPLITUDE_API_KEY_PROGRAM === undefined) {
      throw new Error('Missing required VUE_APP_AMPLITUDE_API_KEY_PROGRAM');
    }

    if (process.env.VUE_APP_AMPLITUDE_API_KEY_APPLICANT === undefined) {
      throw new Error('Missing required VUE_APP_AMPLITUDE_API_KEY_APPLICANT');
    }

    if (userId === undefined) {
      throw new Error('userId is undefined');
    }

    const apiKey =
      section === 'program'
        ? process.env.VUE_APP_AMPLITUDE_API_KEY_PROGRAM
        : process.env.VUE_APP_AMPLITUDE_API_KEY_APPLICANT;

    amplitude.getInstance().init(apiKey, userId);
  }
}

export function logEvent({
  eventName,
  userProperties,
  eventProperties = {}
}: AmplitudeArguments) {
  if (process.env.VUE_APP_AMPLITUDE_ENABLED === 'true') {
    const amplitudeInstance = amplitude.getInstance();

    if (userProperties !== undefined) {
      amplitudeInstance.setUserProperties(userProperties);
    }

    amplitudeInstance.logEvent(eventName, {
      ...eventProperties,
      date: format(new Date(), 'MMM d, y h:mm:ss a')
    });
  }
}
