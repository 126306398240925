export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "noDataAvailableSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We cannot find any results based on the combination you have selected, please try a different combination."])},
        "noDataAvailableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No data available to display"])}
      },
      "fr": {
        "noDataAvailableSubtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne trouvons aucun résultat à partir de la combinaison d'options que vous avez sélectionnées, veuillez essayer une combinaison différente."])},
        "noDataAvailableTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée disponible"])}
      }
    }
  })
}
