<template>
  <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      class="stroke-current fill-current"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.52864 4.02864C5.78899 3.76829 6.2111 3.76829 6.47145 4.02864L10.4714 8.02864C10.7318 8.28899 10.7318 8.7111 10.4714 8.97145L6.47145 12.9714C6.2111 13.2318 5.78899 13.2318 5.52864 12.9714C5.26829 12.7111 5.26829 12.289 5.52864 12.0286L9.05723 8.50004L5.52864 4.97145C5.26829 4.7111 5.26829 4.28899 5.52864 4.02864Z"
    />
  </svg>
</template>
