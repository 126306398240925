<template>
  <div class="mt-4 relative h-2 bg-gray-lighter overflow-hidden rounded-lg">
    <div
      class="h-full absolute rounded-lg"
      :class="getBackgroundColor"
      :style="{
        transition: 'width 0.2s linear',
        width: `${percentage}%`
      }"
    ></div>
  </div>
  <div v-if="showNumber" class="text-gray-darker text-sm text-center mt-2">
    {{ percentage }}%
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

const variants: Record<string, string | undefined> = {
  primary: 'bg-blue',
  secondary: 'bg-teal',
  danger: 'bg-red',
  success: 'bg-green',
  warning: 'bg-orange',
  info: 'bg-babyblue',
  neutral: 'bg-gray'
};

export default defineComponent({
  name: 'ProgressBar',
  props: {
    percentage: { type: Number, default: 0 },
    showNumber: { type: Boolean },
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string) => Object.keys(variants).includes(value)
    }
  },
  setup(props) {
    const getBackgroundColor = computed(() => {
      return variants[props.variant] ?? '';
    });

    return {
      getBackgroundColor
    };
  }
});
</script>
