<template>
  <div class="px-7 py-6 card border border-gray-light rounded-lg">
    <div class="font-normal text-gray-darker">
      <slot />
    </div>
    <div class="mt-0.5 font-bold text-base text-blue-darkest">
      <slot name="content" />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.card {
  background-color: #fcfcfc;
}
</style>
