<template>
  <Modal name="default" v-bind="modal" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { injectModals } from './modal';
import Modal from './Modal.vue';

export default defineComponent({
  name: 'DefaultModal',
  components: { Modal },
  setup() {
    const modals = injectModals();

    return {
      modal: modals.currentModal
    };
  }
});
</script>
