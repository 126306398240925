// For API reference: https://www.smashingmagazine.com/2020/12/vuex-library/

import {
  computed,
  DeepReadonly,
  InjectionKey,
  reactive,
  readonly,
  Ref,
  UnwrapRef
} from 'vue';

type UnwrapNestedRefs<T> = T extends Ref ? T : UnwrapRef<T>;
type ReadonlyStore<Store> = DeepReadonly<UnwrapNestedRefs<Store>>;
export type StoreDefinition<Store> = InjectionKey<ReadonlyStore<Store>>;

const storeCreators = new Map<StoreDefinition<unknown>, StoreCreator<unknown>>();
const storeInstances = new Map<StoreDefinition<unknown>, ReadonlyStore<unknown>>();

export function defineStore<Store extends object>(
  name: string,
  createStore: StoreCreator<Store>
): StoreDefinition<Store> {
  const key = Symbol(`store:${name}`);
  storeCreators.set(key, createStore);
  return key;
}

function setupStore<Store extends object>(
  storeDefinition: StoreDefinition<Store>
): ReadonlyStore<Store> {
  const createStore = storeCreators.get(storeDefinition) as
    | StoreCreator<Store>
    | undefined;
  if (createStore === undefined) {
    throw new Error('No such store defined');
  }
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const original = createStore({ use: useStore });
  const instance = readonly(original);
  storeInstances.set(storeDefinition, instance);
  return instance;
}

export function useStore<Store extends object>(
  storeDefinition: StoreDefinition<Store>
): ReadonlyStore<Store> {
  const instance = storeInstances.get(storeDefinition);
  if (instance !== undefined) {
    return instance as ReadonlyStore<Store>;
  }
  return setupStore(storeDefinition);
}

type StoreCreator<Store> = (context: { use: typeof useStore }) => Store;

export type StoreType<Definition> = Definition extends StoreDefinition<infer Store>
  ? ReadonlyStore<Store>
  : never;

export function mutableRef<SourceType>(source: SourceType) {
  const result = reactive({
    value: source,
    set: (newValue: UnwrapRef<SourceType>) => (result.value = newValue)
  });
  return result;
}

export function toMutable<SourceType>(
  mutableRefSource: DeepReadonly<
    UnwrapRef<{
      value: SourceType;
      set: (newValue: DeepReadonly<UnwrapRef<SourceType>>) => void;
    }>
  >
) {
  return computed({
    get: () => mutableRefSource.value,
    set: mutableRefSource.set
  });
}
