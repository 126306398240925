export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants"])},
        "goToInsights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Insights"])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers"])},
        "snapshotLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snapshot logo"])},
        "srCurrentUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current user"])}
      },
      "fr": {
        "applicants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidats"])},
        "goToInsights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer Insights"])},
        "reviewers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Examinateurs"])},
        "snapshotLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo Snapshot"])},
        "srCurrentUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur actuel"])}
      }
    }
  })
}
