import { render } from "./Alert.vue?vue&type=template&id=6518eb27&scoped=true"
import script from "./Alert.vue?vue&type=script&lang=ts"
export * from "./Alert.vue?vue&type=script&lang=ts"

import "./Alert.vue?vue&type=style&index=0&id=6518eb27&lang=postcss&scoped=true"
script.render = render
script.__scopeId = "data-v-6518eb27"
/* custom blocks */
import block0 from "./Alert.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(script)


export default script