import {
  User,
  SupportConfig,
  ApplicantTests,
  ApplicantTestDetails,
  Answer,
  Test,
  School,
  Submission,
  RaterStats,
  Rating,
  Permission
} from '@/interfaces/interfaces';
import { App, inject, InjectionKey } from 'vue';
import axios, { AxiosInstance } from 'axios';

if (!process.env.VUE_APP_BACKEND_URL) {
  throw new Error('Missing required backend URL env variable');
}

const key: InjectionKey<ApiClient> = Symbol('apiClient');

export class ApiClient {
  protected backend: AxiosInstance;

  constructor() {
    this.backend = axios.create({
      baseURL: process.env.VUE_APP_BACKEND_URL,
      headers: { common: { Accept: 'application/json' } },
      responseType: 'json'
    });
  }

  setAccessToken(accessToken: string | null) {
    if (accessToken === null) {
      delete this.backend.defaults.headers.common.Authorization;
    } else {
      this.backend.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  }

  // Using any since we don't know what type of error we might have and with unknown we would need to cast everytime we use it
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addResponseErrorInterceptor(interceptor: (error: any) => void | Promise<void>) {
    this.backend.interceptors.response.use(undefined, interceptor);
  }

  async getCurrentUser() {
    const res = await this.backend.get<User>('/auth/user');
    return res.data;
  }

  async getSupportConfig() {
    const res = await this.backend.get<SupportConfig>('/auth/support');
    return res.data;
  }

  async getApplicantTests() {
    const res = await this.backend.get<ApplicantTests>('/applicant/tests');
    return res.data;
  }

  async getApplicantTestDetails(testId: string) {
    const res = await this.backend.get<ApplicantTestDetails>(
      `/applicant/tests/${testId}`
    );
    return res.data;
  }

  async submitAnswer(
    testQuestionId: string,
    videoFilename: string,
    videoDuration: number
  ) {
    const res = await this.backend.post<Answer>('/applicant/answers', {
      testQuestionId,
      videoFilename,
      videoDuration
    });
    return res.data;
  }

  protected async uploadFile(
    url: string,
    file: Blob,
    progressCallback: (percentageComplete: number) => void
  ) {
    await axios.put(url, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress(event: ProgressEvent) {
        if (event.lengthComputable) {
          const percentageComplete = (event.loaded / file.size) * 100;
          progressCallback(percentageComplete);
        }
      }
    });
  }

  /**
   * Uploads a Snapshot video to S3.
   * @return File name of the uploaded video.
   */
  async uploadSnapshotVideo(
    video: Blob,
    progressCallback: (percentageComplete: number) => void
  ) {
    const res = await this.backend.get<{ url: string; fileName: string }>(
      '/upload/snapshot'
    );
    await this.uploadFile(res.data.url, video, progressCallback);
    return res.data.fileName;
  }

  /**
   * Uploads a video to S3 for system check. These videos will get deleted periodically.
   * @return File name of the uploaded video.
   */
  async uploadSystemCheckVideo(
    video: Blob,
    progressCallback: (percentageComplete: number) => void
  ) {
    const res = await this.backend.get<{ url: string; fileName: string }>(
      '/upload/system-check'
    );
    await this.uploadFile(res.data.url, video, progressCallback);
    return res.data.fileName;
  }

  async getPracticeVideoUrl(fileName: string) {
    const res = await this.backend.get<{ url: string }>(
      `/system-check-video/${fileName}`
    );
    return res.data.url;
  }

  async submitSurveyAnswers(
    id: string,
    surveySubmission: Array<{ questionEntryId: string; value: number | string }>
  ) {
    await this.backend.post(`/surveys/${id}/submit`, surveySubmission);
  }

  async getSchoolsAndTests() {
    const res = await this.backend.get<{
      schools: School[];
      tests: Test[];
      permissions: Permission[];
    }>('/program/school-tests');
    return res.data;
  }

  async getSubmissions(schoolId: string, testId: string) {
    const res = await this.backend.get<Submission[]>(
      `/program/schools/${schoolId}/tests/${testId}/submissions`
    );
    return res.data;
  }

  async getRaters(schoolId: string, testId: string) {
    const res = await this.backend.get<{
      raters: User[];
      stats: RaterStats[];
      permissions: Permission[];
    }>(`/program/schools/${schoolId}/tests/${testId}/raters`);
    return res.data;
  }

  async getAnswersFromSubmission(submissionId: string) {
    const res = await this.backend.get<{
      answers: Answer[];
      videoUrls: { fileName: string; url: string }[];
    }>(`program/submissions/${submissionId}/answers`);
    return res.data;
  }

  async createRating(params: {
    schoolId: string;
    testId: string;
    submissionIds: string[];
    userIds: string[];
    score?: number;
    comment?: string | null;
  }) {
    const res = await this.backend.post<Rating[]>('/program/ratings', params);
    return res.data;
  }

  async scoreRating(ratingId: string, params: { score: number; comment: string | null }) {
    const res = await this.backend.put<Rating>(`program/ratings/${ratingId}`, params);
    return res.data;
  }

  async deleteRating(ratingId: string) {
    const res = await this.backend.delete<Rating>(`/program/ratings/${ratingId}`);
    return res.data;
  }
}

export function loadApiClient(app: App) {
  const client = new ApiClient();
  app.provide(key, client);
  return client;
}

export function injectApiClient() {
  const client = inject(key);
  if (client === undefined) {
    throw new Error('ApiClient instance is missing');
  }
  return client;
}
