import { User } from '@/interfaces/interfaces';
import { Section } from '@/router/guards';
import { injectApiClient } from '@/services/apiClient';
import { ref, computed, watchEffect } from 'vue';
import { defineStore, StoreType } from './lib/store';
import { setupSupportWidget } from '@/services/util';
import { initializePendoUser, pendoInit, PendoUserType } from '@/services/pendo';

export const authStore = defineStore('auth', () => {
  const accessToken = ref<string | null>(null);
  const user = ref<User | null>(null);
  const userName = computed(() => user.value?.profile?.name);

  const client = injectApiClient();

  const isAuthenticated = computed(
    () => accessToken.value !== null && user.value !== null
  );
  const isSchoolAdmin = computed(() => user.value?.roles?.includes('school-admin'));
  const isRater = computed(() => user.value?.roles?.includes('team-member'));
  const isApplicant = computed(() => !isSchoolAdmin.value && !isRater.value);

  async function loadUserData() {
    if (accessToken.value === null) {
      throw new Error('Cannot load user data without an access token');
    }
    user.value = await client.getCurrentUser();
  }

  async function logIn(token: string) {
    accessToken.value = token;
    client.setAccessToken(accessToken.value);
    await loadUserData();
    window.localStorage.setItem('accessToken', accessToken.value);
  }

  function logOut() {
    accessToken.value = null;
    client.setAccessToken(null);
    user.value = null;
    window.localStorage.removeItem('accessToken');
  }

  async function restoreSession() {
    const token = window.localStorage.getItem('accessToken');
    if (!token) {
      throw new Error('No access token stored to restore from');
    }
    try {
      await logIn(token);
    } catch (error) {
      logOut();
      throw error;
    }
  }

  const pendoLoaded = ref(false);
  let timer: number;
  function pendoCallback() {
    clearTimeout(timer);
    timer = window.setTimeout(() => {
      if (window?.pendo?.initialize) {
        pendoLoaded.value = true;
        clearTimeout(timer);
      } else {
        pendoCallback();
      }
    }, 500);
  }

  async function launchPendoTracker(userType: PendoUserType) {
    await pendoInit(userType, pendoCallback);
  }

  watchEffect(() => {
    if (pendoLoaded.value && user.value !== null) {
      initializePendoUser({
        userId: user.value.id,
        role: user?.value.roles?.[0],
        isAltan: process.env.VUE_APP_INTERNAL_DOMAIN_ADDRESSES?.split(',').some(
          (domain: string) => {
            return user?.value?.emails.some(
              (email: { address: string; verified: boolean }) =>
                email?.address?.toLowerCase().includes(domain)
            );
          }
        )
      });
    }
  });

  async function launchSupportWidget(section: Section) {
    const config = await client.getSupportConfig();
    setupSupportWidget(section, config);
  }

  return {
    accessToken,
    user,
    userName,
    isAuthenticated,
    isSchoolAdmin,
    isRater,
    isApplicant,
    logIn,
    restoreSession,
    logOut,
    launchPendoTracker,
    launchSupportWidget
  };
});

export type AuthStore = StoreType<typeof authStore>;
