
import { defineComponent, computed } from 'vue';

const variants: Record<string, string | undefined> = {
  primary: 'bg-blue',
  secondary: 'bg-teal',
  danger: 'bg-red',
  success: 'bg-green',
  warning: 'bg-orange',
  info: 'bg-babyblue',
  neutral: 'bg-gray'
};

export default defineComponent({
  name: 'ProgressBar',
  props: {
    percentage: { type: Number, default: 0 },
    showNumber: { type: Boolean },
    variant: {
      type: String,
      default: 'primary',
      validator: (value: string) => Object.keys(variants).includes(value)
    }
  },
  setup(props) {
    const getBackgroundColor = computed(() => {
      return variants[props.variant] ?? '';
    });

    return {
      getBackgroundColor
    };
  }
});
