<template>
  <router-link
    v-if="!disabled"
    :to="to"
    class="card-link"
    :class="disabled ? 'text-gray-light' : 'text-blue'"
  >
    <a-chevron
      v-if="leftChevron"
      class="w-4 mr-1 transform rotate-180"
      aria-hidden="true"
    />
    <slot />
    <a-chevron v-if="!leftChevron" class="w-4 ml-1" aria-hidden="true" />
  </router-link>
  <button
    v-else
    disabled
    class="cursor-not-allowed flex items-center"
    :class="disabled ? 'text-gray-light' : 'text-blue'"
  >
    <a-chevron
      v-if="leftChevron"
      class="w-4 mr-1 transform rotate-180"
      aria-hidden="true"
    />
    <slot />
    <a-chevron v-if="!leftChevron" class="w-4 ml-1" aria-hidden="true" />
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardLink',
  props: {
    disabled: Boolean,
    to: null,
    leftChevron: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<style lang="postcss" scoped>
.card-link {
  @apply flex transition-colors items-center;

  &:hover {
    @apply text-blue-darkest underline;
  }

  &:focus-visible {
    @apply outline-none ring-2 ring-blue ring-offset-1;
  }

  /* Styling does not apply unless :moz-focusring is separated from :focus-visible, which is why it is repeated below */
  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue ring-offset-1;
  }
}
</style>
