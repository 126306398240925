
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardLink',
  props: {
    disabled: Boolean,
    to: null,
    leftChevron: {
      type: Boolean,
      default: false
    }
  }
});
