import { InjectionKey, App, provide, inject, reactive, computed, ref } from 'vue';

interface ModalProps {
  title?: string;
  content?: string;
  dismissible?: boolean;
  footer?: boolean;
  acceptButton?: string | boolean;
  cancelButton?: string | boolean;
  width?: string;
}

export interface ModalHandler {
  readonly visible: boolean;
  open(): Promise<boolean>;
  close(result: boolean): void;
  accept(): void;
  dismiss(): void;
}

export function createModalManager() {
  const modals: Record<string, ModalHandler | undefined> = reactive({});

  function registerModal(name: string, handler: ModalHandler) {
    modals[name] = handler;
  }

  function unregisterModal(name: string) {
    delete modals[name];
  }

  function get(name: string) {
    return computed(() => modals[name]);
  }

  const defaultModal = get('default');
  const currentModal = ref<null | ModalProps>(null);

  function open(params: ModalProps) {
    if (defaultModal.value === undefined) {
      throw new Error('No default modal has been mounted');
    }
    currentModal.value = params;
    return defaultModal.value.open();
  }

  return {
    registerModal,
    unregisterModal,
    get,
    open,
    currentModal
  };
}

const key: InjectionKey<ReturnType<typeof createModalManager>> = Symbol('modal');

export function provideModals(app?: App) {
  const modal = createModalManager();
  if (app !== undefined) {
    app.provide(key, modal);
  } else {
    provide(key, modal);
  }
  return modal;
}

export function injectModals() {
  const modal = inject(key);
  if (modal === undefined) {
    throw new Error('Modal manager instance is missing');
  }
  return modal;
}
