<template>
  <transition name="fade">
    <div
      v-if="!dismissed"
      role="alert"
      class="p-4 flex items-start justify-between rounded text-gray-dark"
      :class="getVariant?.class"
    >
      <div v-if="icon" class="mr-4 flex-none mt-0.5">
        <img :src="getVariant?.icon" alt="" class="w-5" />
      </div>
      <div class="flex-grow">
        <slot />
      </div>
      <div class="flex-none ml-4">
        <button
          v-if="dismissible"
          @click="onDismissed ? $emit('dismissed') : (dismissed = true)"
          class="dismiss-button"
          data-cy="alert-dismiss-button"
        >
          <span class="sr-only">{{ t('srDismissAlert') }}</span>
          <img src="@/assets/icons/x-solid.svg" alt="" class="w-5" />
        </button>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { defineComponent, computed, ref } from 'vue';
import { useI18n } from '@/services/i18n';

import dangerIcon from '@/assets/icons/x-circle-solid.svg';
import successIcon from '@/assets/icons/check-circle-solid.svg';
import warningIcon from '@/assets/icons/exclamation-triangle.svg';
import infoIcon from '@/assets/icons/i-circle-solid.svg';

const variants: Record<string, { class: string; icon: string } | undefined> = {
  danger: {
    class: 'danger',
    icon: dangerIcon
  },
  success: {
    class: 'success',
    icon: successIcon
  },
  warning: {
    class: 'warning',
    icon: warningIcon
  },
  info: {
    class: 'info',
    icon: infoIcon
  }
};

export default defineComponent({
  name: 'Alert',
  props: {
    icon: { type: Boolean },
    variant: {
      type: String,
      default: 'info',
      validator: (value: string) => Object.keys(variants).includes(value)
    },
    dismissible: { type: Boolean },
    onDismissed: { type: Function, required: false }
  },
  emits: ['dismissed'],
  setup(props) {
    const dismissed = ref(false);

    const getVariant = computed(() => {
      return variants[props.variant] ?? variants.info;
    });

    return {
      dismissed,
      getVariant,
      t: useI18n().t
    };
  }
});
</script>

<style lang="postcss" scoped>
.danger {
  @apply bg-red-lightest;
}

.success {
  @apply bg-green-lightest;
}

.warning {
  @apply bg-orange-lightest;
}

.info {
  @apply bg-babyblue-lightest;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.dismiss-button {
  &:focus {
    @apply outline-none;
  }

  &:focus-visible {
    @apply outline-none ring-2 ring-blue ring-offset-2;
  }

  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue ring-offset-2;
  }
}
</style>

<i18n>
{
  "en": {
    "srDismissAlert": "Dismiss Alert"
  },
  "fr": {
    "srDismissAlert": "Annuler l'alerte"
  }
}
</i18n>
