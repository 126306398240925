<template>
  <teleport to="body">
    <transition name="modal">
      <div
        class="overlay flex justify-center items-center"
        :class="{ 'p-2': width === 'xl' }"
        v-if="visible"
        @click.self="dismissible && dismiss()"
      >
        <div
          class="modal-card"
          :class="{
            'w-1/3': width === 'md',
            'w-1/4': width === 'sm',
            'w-1/2': width === 'lg',
            'w-full': width === 'xl'
          }"
        >
          <div v-if="$slots.header === undefined && title === undefined" class="p-6 pb-0">
            <button
              v-if="dismissible"
              class="dismiss-button block ml-auto"
              @click="dismiss()"
            >
              <span class="sr-only">{{ t('srDismissModal') }}</span>
              <img src="@/assets/icons/x-solid.svg" alt="" class="w-5" />
            </button>
          </div>
          <div class="header flex" v-else>
            <div>
              <slot name="header" v-bind="{ dismiss, accept, close }">
                <h2>{{ title }}</h2>
              </slot>
            </div>
            <button
              v-if="dismissible"
              class="dismiss-button justify-self-end"
              @click="dismiss()"
            >
              <span class="sr-only">{{ t('srDismissModal') }}</span>
              <img src="@/assets/icons/x-solid.svg" alt="" class="w-5" />
            </button>
          </div>
          <div class="content">
            <slot name="content" v-bind="{ dismiss, accept, close }">
              {{ content }}
            </slot>
          </div>
          <div class="footer" v-if="footer">
            <slot name="footer" v-bind="{ dismiss, accept, close }">
              <div class="text-right">
                <a-button v-if="cancelButton" variant="outline" @click="dismiss()">
                  {{ typeof cancelButton === 'string' ? cancelButton : t('cancel') }}
                </a-button>
                <a-button v-if="acceptButton" class="ml-2" @click="accept()">
                  {{ typeof acceptButton === 'string' ? acceptButton : t('accept') }}
                </a-button>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, onMounted, onBeforeMount, reactive, readonly, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { injectModals, ModalHandler } from './modal';

export default defineComponent({
  name: 'Modal',
  props: {
    name: { type: String },
    dismissible: { type: Boolean, default: true },
    title: { type: String },
    content: { type: String },
    footer: { type: Boolean, default: true },
    acceptButton: { type: [String, Boolean], default: true },
    cancelButton: { type: [String, Boolean], default: true },
    width: {
      type: String,
      default: 'md',
      validator: (value: string) => ['sm', 'md', 'lg', 'xl'].includes(value)
    }
  },
  setup(props) {
    const modals = injectModals();

    const visible = ref(false);
    let promise = Promise.resolve(false);

    let resolve: null | ((result: boolean) => void) = null;

    function open() {
      visible.value = true;
      promise = new Promise(res => {
        resolve = res;
      });
      return promise;
    }

    function close(result: boolean) {
      visible.value = false;
      resolve?.(result);
    }

    function accept() {
      close(true);
    }

    function dismiss() {
      close(false);
    }

    onMounted(() => {
      if (typeof props.name === 'string') {
        const handler: ModalHandler = readonly(
          reactive({
            visible,
            open,
            close,
            accept,
            dismiss
          })
        );
        modals.registerModal(props.name, handler);
      }
    });
    onBeforeMount(() => {
      if (typeof props.name === 'string') {
        modals.unregisterModal(props.name);
      }
    });

    return {
      t: useI18n().t,
      visible,
      accept,
      dismiss,
      close
    };
  }
});
</script>

<style lang="postcss" scoped>
.overlay {
  @apply fixed inset-0 overflow-auto;
  @apply bg-black bg-opacity-40 backdrop-filter backdrop-blur-sm;
}

.modal-card {
  @apply bg-white rounded-sm shadow-lg;
}

.header {
  @apply p-6 font-bold text-blue-darkest text-base border-b border-gray-light;
}

.content {
  @apply p-6 text-blue-darkest text-sm;
}

.footer {
  @apply p-6;
}

.dismiss-button {
  @apply ml-auto;

  &:focus {
    @apply outline-none;
  }

  &:focus-visible {
    @apply outline-none ring-2 ring-blue ring-offset-2;
  }

  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue ring-offset-2;
  }
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.5s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: scale(1.2);
}
</style>

<i18n>
{
  "en": {
    "accept": "Ok",
    "cancel": "Cancel",
    "srDismissModal": "Dismiss modal"
  },
  "fr": {
    "accept": "Valider",
    "cancel": "Annuler",
    "srDismissModal": "Fermer la fenêtre"
  }
}
</i18n>
