import { RouteRecordRaw } from 'vue-router';
import ProgramLayout from '@/views/program/ProgramLayout.vue';
import { Guards } from './guards';

export default function generateProgramRoutes(guards: Guards): RouteRecordRaw[] {
  return [
    {
      path: '/program',
      name: 'program',
      meta: { section: 'program' },
      component: ProgramLayout,
      redirect: { name: 'program.applicants' },
      beforeEnter: guards.requireRole(['school-admin', 'team-member']),
      children: [
        {
          path: 'applicants',
          name: 'program.applicants',
          meta: { title: 'Snapshot Applicants' },
          component: () =>
            import(
              /* webpackChunkName: "program-applicants" */ '@/views/program/ProgramApplicants.vue'
            )
        },
        {
          path: 'reviewers',
          name: 'program.reviewers',
          meta: { title: 'Snapshot Reviewers' },
          beforeEnter: guards.requireRole('school-admin'),
          component: () =>
            import(
              /* webpackChunkName: "program-reviewers" */ '@/views/program/ProgramReviewers.vue'
            )
        },
        {
          path: 'assign-applicants/:raterId',
          name: 'program.assignApplicants',
          props: true,
          meta: { title: 'Assign Applicants', hideFooter: true },
          beforeEnter: [
            guards.requireRole('school-admin'),
            guards.requireProgramTestSelected({ name: 'program.reviewers' })
          ],
          component: () =>
            import(
              /* webpackChunkName: "program-assign-applicants" */ '@/views/program/ProgramAssignApplicants.vue'
            )
        },
        {
          path: 'submissions/:submissionId',
          name: 'program.submission',
          props: true,
          meta: { title: 'Snapshot Submission', hideFooter: true },
          beforeEnter: guards.requireProgramTestSelected({ name: 'program.applicants' }),
          component: () =>
            import(
              /* webpackChunkName: "program-submission-rating" */ '@/views/program/ProgramSubmissionRating.vue'
            )
        },
        {
          path: 'rating-scale/:testId',
          name: 'program.rating-scale',
          props: true,
          meta: { title: 'Snapshot Rating Scale', hideNavLinks: true },
          component: () =>
            import(
              /* webpackChunkName: "program-rating-scale" */ '@/views/program/ProgramRatingScale.vue'
            )
        }
      ]
    }
  ];
}
