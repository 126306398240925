<template>
  <a-collapsible-card fixed expanded>
    <div class="flex items-center">
      <div class="flex-none">
        <slot name="icon" />
      </div>
      <div class="flex-grow">
        <slot name="title" />
      </div>
    </div>

    <template #content>
      <div class="card-content">
        <slot name="content" />
      </div>
    </template>
  </a-collapsible-card>
</template>
