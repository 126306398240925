<template>
  <footer class="border-t border-gray-lighter px-32 py-10 text-sm">
    <p class="inline-block">
      {{ t('copyright', { currentYear: new Date().getFullYear() }) }}
    </p>
    <div class="inline-block float-right" v-if="languageSelector">
      <button class="lang-button" @click="selectLanguage('en')">
        {{ getLangOwnName('en') }}
      </button>
      <span class="mx-4">|</span>
      <button class="lang-button" @click="selectLanguage('fr')">
        {{ getLangOwnName('fr') }}
      </button>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from '@/services/i18n';
import { getLangOwnName } from '@/services/langNames';
import { Language } from '@/interfaces/interfaces';

export default defineComponent({
  name: 'Footer',
  props: {
    languageSelector: { type: Boolean, default: false }
  },
  setup() {
    const i18n = useI18n();
    const i18nGlobal = useI18n({ useScope: 'global' });

    function selectLanguage(lang: Language) {
      i18nGlobal.locale.value = lang;
    }

    return {
      t: i18n.t,
      getLangOwnName,
      selectLanguage
    };
  }
});
</script>

<style lang="postcss" scoped>
.lang-button {
  @apply transition;

  &:hover,
  &:active {
    @apply text-blue;
  }

  &:focus {
    @apply outline-none text-blue;
  }

  &:focus-visible {
    @apply outline-none text-blue ring-2 ring-blue ring-offset-2;
  }

  &:-moz-focusring {
    @apply outline-none text-blue ring-2 ring-blue ring-offset-2;
  }
}
</style>

<i18n>
{
  "en": {
    "copyright": "Copyright © 2020-{currentYear} Acuity Insights Inc."
  },
  "fr": {
    "copyright": "Droits d’auteur © 2020-{currentYear} Acuity Insights Inc."
  }
}
</i18n>
